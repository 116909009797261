import {makeAutoObservable} from "mobx";
import {RemoteCfg} from "../firebaseConfig";
import LocaleService from "../services/LocaleService";
import {localeStore} from "../index";

export class RCStore {
    isInited = false;
    newLangsActive = false;
    useOAuthBtns = true; // true by default
    newYearMakeUp = false; // false by default
    contentTypes = "img"; // img by default
    promoList: RemoteCfg[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    setIsInited(b: boolean) {
        this.isInited = true
    }

    getIsInited() {
        return this.isInited
    }

    setNewLangsActive(b: boolean) {
        this.newLangsActive = b;
        if (b) {
            LocaleService.getLanguages(b).then(langList => {
                localeStore.setLanguageList(langList);
            })
        }
    }

    getNewLangsActive() {
        return this.newLangsActive
    }

    setUseOAuthBtns(b: boolean) {
        this.useOAuthBtns = b
    }

    getUseOAuthBtns() {
        return this.useOAuthBtns
    }

    setNewYearMakeUp(b: boolean) {
        this.newYearMakeUp = b
    }

    getNewYearMakeUp() {
        return this.newYearMakeUp
    }

    setContentTypes(s: string) {
        this.contentTypes = s
    }

    getContentTypes() {
        return this.contentTypes
    }

    setPromoList(list: RemoteCfg[]) {
        this.promoList = list
    }

    appendPromoList(rcp: RemoteCfg) {
        this.promoList.push(rcp)
    }

    getPromoList() {
        return this.promoList
    }
}