import React, {useContext, useEffect, useState} from "react";
import cl from "./MainBanners.module.css";
import {useTranslation} from "react-i18next";
import cookie_attention_img from "../images/ui/cookie-attention-low.png"
import close_img from "../images/ui/close.png"
import app_store_wide_img from "../images/navbar/App_Store_Logo_Wide.png"
import play_market_wide_img from "../images/navbar/Google_Play_Logo_Wide.png"
import app_gallery_wide_img from "../images/navbar/App_Gallery_Logo_Wide.png"
import {NavLink, useNavigate} from "react-router-dom";
import {config} from "../../config";
import {RemoteCfg} from "../../firebaseConfig";
import PromoService from "../../services/Promotion";
import {useFetching} from "../../hooks/useFetching";
import {observer} from "mobx-react-lite";
import {Context} from "../../index";
import {PromoMemeDevInfo} from "../../models/Promo";
import {djbHash} from "../../pkg/Strings";

export const hasUserReadCookieAttentionKey = "cookieAttentionHasBeenRead"
export const hasUserReadMobileMarketNoticeKey = "mobileMarketNoticeHasBeenRead"

interface CookieAttentionProps {
    setIsCookieRead(b: boolean): void
}

export function CookieAttention({setIsCookieRead}: CookieAttentionProps) {
    const { t } = useTranslation();
    const [isHidden, setIsHidden] = useState(false);
    const [isUnmounted, setIsUnmounted] = useState(false);
    function handleClickButton() {
        localStorage.setItem(hasUserReadCookieAttentionKey, "true");
        setIsHidden(true);
        setTimeout(() => {
            setIsUnmounted(true);
            setIsCookieRead(true);
        }, 500); // 500ms for transition animation
    }
    return (
        <div className={`${cl.Container} ${isHidden ? cl.hidden : ""}`}
             style={isUnmounted ? {display: "none"} : {}}>
            <img className={cl.CookieImg} src={cookie_attention_img} alt={"cookie_img"}/>
            <div className={cl.CookieInfoTitle}>
                { t("ui.cookieAttention.title") }
            </div>
            <div className={cl.CookieInfo}>
                { t("ui.cookieAttention.info") }
            </div>
            <div className={cl.UnderstoodBtn}
                 onClick={handleClickButton}>
                <img className={cl.CloseImg}
                     src={close_img} alt={"close_img"}/>
                {/*{ t("ui.cookieAttention.understood") }*/}
            </div>
        </div>
    );
}

interface MobileMarketNoticeProps {
    setIsMobileMarketNoticeRead(b: boolean): void
}

export function MobileMarketNotice({setIsMobileMarketNoticeRead}: MobileMarketNoticeProps) {
    const { t } = useTranslation();
    const [isHidden, setIsHidden] = useState(false);
    const [isUnmounted, setIsUnmounted] = useState(false);
    function handleClickButton() {
        setIsHidden(true);
        localStorage.setItem(hasUserReadMobileMarketNoticeKey, "true");
        setTimeout(() => {
            setIsUnmounted(true);
            setIsMobileMarketNoticeRead(true);
        }, 500); // 500ms for transition animation
    }
    return (
        <div className={`${cl.Container} ${isHidden ? cl.hidden : ""}`}
             style={isUnmounted ? {display: "none"} : {}}>
            <div className={cl.MobileMarketTitle}>
                { t("ui.mobileMarketNoticeTitle") }
            </div>
            <div className={cl.MobileMarketLinkListContainer}>
                <NavLink className={cl.MobileMarketLink}
                         to={config.IOSStore}>
                    <img className={cl.MobileMarketLinkImg}
                         src={app_store_wide_img} alt={"app_store_wide_img"}/>
                </NavLink>
                <NavLink className={cl.MobileMarketLink}
                         to={config.AndroidStore}>
                    <img className={cl.MobileMarketLinkImg}
                         src={play_market_wide_img} alt={"play_market_wide_img"}/>
                </NavLink>
                <NavLink className={cl.MobileMarketLink}
                         to={config.HuaweiStore}>
                    <img className={cl.MobileMarketLinkImg}
                         src={app_gallery_wide_img} alt={"app_gallery_wide_img"}/>
                </NavLink>
            </div>
            <div className={cl.UnderstoodBtn}
                 onClick={handleClickButton}>
                <img className={cl.CloseImg}
                     src={close_img} alt={"close_img"}/>
                {/*{ t("ui.cookieAttention.understood") }*/}
            </div>
        </div>
    );
}

interface PromoBannerProps {
    setAuthModal(b: boolean): void,
    setIsRegister(b: boolean): void,
}

export const PromoBanner = observer(({setAuthModal, setIsRegister}: PromoBannerProps) => {
    const { authStore, localeStore, rcStore} = useContext(Context);
    const navigate = useNavigate();
    const [banner, setBanner] = useState<string | null>(null);
    const { i18n } = useTranslation();
    function getShownBannerHashList(): number[] {
        try {
            const hashListStr = localStorage.getItem("shownBannersHashList");
            if (hashListStr) {
                const hashList: number[] = JSON.parse(hashListStr);
                if (Array.isArray(hashList) && hashList.every(item => typeof item === 'number')) {
                    return hashList;
                }
            }
        } catch (e) {
            console.log(e);
        }
        return [];
    }
    function isBannerHasBeenShown(rcp: RemoteCfg, shownBannerHashList: number[]): boolean {
        const bnrHash = djbHash(JSON.stringify(rcp));
        return shownBannerHashList.includes(bnrHash);
    }
    const [isHidden, setIsHidden] = useState(false);
    const [isUnmounted, setIsUnmounted] = useState(false);
    const [handleClickCloseButton,  setHandleClickCloseButton] = useState<() => void>(() => () => {});

    const [fetchBanner, isFetching, error] = useFetching(async (rcPromo: RemoteCfg) => {
        const lastTimeStr = localStorage.getItem("lastTimeBannerShown");
        let lastTime = 0; // set zero time
        if (lastTimeStr !== null) {
            lastTime = parseInt(lastTimeStr, 10); // set last time when banner was shown
        }
        const now = Date.now();
        if (now - lastTime > 2 * 60 * 60 * 1000) { // if 2 hours passed
            const userInfo: PromoMemeDevInfo = {
                id: authStore.user?.id || 0,
                lang: i18n.language,
                reg: localeStore.getRegion(),
                isMobile: false,
            }
            //unsafe, html code should only come from our server
            const bnr = await PromoService.getPromoHtml(rcPromo.url, userInfo);
            setBanner(bnr);
            localStorage.setItem("lastTimeBannerShown", now.toString())
        }
    });
    useEffect(() => {
        if (authStore.isInited && rcStore.isInited) {
            for (let rcp of rcStore.getPromoList()) {
                if (rcp && rcp.is_enabled) {
                    const hashList = getShownBannerHashList();
                    if (!isBannerHasBeenShown(rcp, hashList)) {
                        const handleClick = () => {
                            hashList.push(djbHash(JSON.stringify(rcp)));
                            localStorage.setItem("shownBannersHashList", JSON.stringify(hashList));
                            setIsHidden(true);
                            setTimeout(() => {setIsUnmounted(true)}, 500); // 500ms for transition animation
                        }
                        setHandleClickCloseButton(() => {
                            return handleClick
                        });
                        if (authStore.isAuth && rcp.auth_type === "for-auth") {
                            fetchBanner(rcp);
                        } else if (!authStore.isAuth && rcp.auth_type === "for-non-auth") {
                            fetchBanner(rcp);
                        } else if (rcp.auth_type === "for-all") {
                            fetchBanner(rcp);
                        }
                        break;
                    }
                }
            }
        }
        // setTimeout(() => {
        //
        // }, 5000);
    }, [authStore.isInited, rcStore.isInited]);

    useEffect(() => {
        if (error) {
            console.log(error);
        }
    }, [error]);

    // event for sign-up promo banner (rcp.auth_type must be "for-non-auth")
    // event for news1 banner (rcp.auth_type must be "for-all")
    useEffect(() => {
        const handleOpenModal = () => {
            if (!authStore.isAuth) {
                setAuthModal(true);
                setIsRegister(true);
            }
            handleClickCloseButton();
        };
        const handleOpenSettings = () => {
            if (authStore.isAuth) {
                navigate("/settings");
            }
            handleClickCloseButton();
        }
        // @ts-ignore
        window.openModal = () => handleOpenModal();
        // @ts-ignore
        window.openSettings = () => handleOpenSettings();
        return () => {
            // @ts-ignore
            window.openModal = null;
            // @ts-ignore
            window.openSettings = null
            };
        }, [handleClickCloseButton, authStore.isInited, authStore.isAuth]);

    return (
        <>
            { banner &&
                <div className={`${cl.PromoBannerContainer} ${isHidden ? cl.hidden : ""}`}
                     style={isUnmounted ? {display: "none"} : {}}>
                    <Bnr bnr={banner}/>
                    <div className={cl.UnderstoodBtn}
                         onClick={handleClickCloseButton}
                         style={{backgroundColor: "var(--main-color)"}}>
                        <img className={cl.CloseImg}
                             src={close_img} alt={"close_img"} style={{filter: "brightness(300%)"}}/>
                    </div>
                </div>
            }
        </>
    );
})

interface BnrProps {
    bnr: string
}

function Bnr({bnr}: BnrProps) {
    const cheerio = require('cheerio');
    const $ = cheerio.load(bnr);
    const script = $('script').html();
    useEffect(() => {
        if (script !== "") {
            eval(script)
        }
    }, []);
    return (
        <div dangerouslySetInnerHTML={{__html: bnr}}>
        </div>
    );
}