export const translationsUZ = {
  common: {
    and: "va",
    or: "yoki",
  },
  ui: {
    defErrMsg: "Nimadir xato ketdi :(",
    notFoundErrMsg: "404. Sahifa topilmadi",
    loading: "Yuklanmoqda...",
    downloadMsg: "Yuklash hozir boshlanadi",
    linkCopied: "Havola buferga ko‘chirildi",
    pending: "Kutilmoqda...",
    goToWebsite: "Saytga o‘tish",
    localMeme: "Mahalliy mem",
    delete: "O‘chirish",
    submit: "Tasdiqlash",
    save: "Saqlash",
    cancel: "Bekor qilish",
    tag: {
      showTags: "Teglarni ko‘rsatish",
      hideTags: "Teglarni yashirish",
      clickToBlock: "Tegni qora ro‘yxatga qo‘shish uchun bosing",
      clickToUnblock: "Tegni qora ro‘yxatdan chiqarish uchun bosing",
      tagBlocked: "Teg bloklandi. Ushbu tegga ega memlar tanlovdan chiqariladi",
      tagUnblocked: "Teg blokdan chiqarildi"
    },
    memeDev: {
      block: "Bloklash",
      unblock: "Blokni olish",
      memeDevBlocked: "Foydalanuvchi bloklandi. Uning kontenti endi tanlovda bo‘lmaydi",
      memeDevUnblocked: "Foydalanuvchi blokdan chiqarildi",
    },
    betaInfo: "Hurmatli foydalanuvchi,\nbeta testda ishtirok etganingiz uchun katta rahmat! Agar xato topsangiz yoki takliflaringiz bo‘lsa, bizga email yozing:",
    mobileMarketNoticeTitle: "Bizning ilovamiz do‘konlarda mavjud:",
    cookieAttention: {
      title: "Biz cookie‘lardan foydalanamiz",
      info: "Saytimizdan foydalanish orqali siz cookie‘lardan foydalanishga rozilik bildirasiz. Avtorizatsiya uchun cookie‘larni qo‘llaymiz. Bu saytdan foydalanishni qulay qiladi.",
      understood: "Tushundim",
    },
    featureTemporarilyDisabled: "Bu funksiya vaqtincha o‘chirilgan, ustida ishlayapmiz",
    memeRatingTitleGlobal: "Global reyting qiymati taxminiy. G‘alabalar, mag‘lubiyatlar va ovozlar soniga bog‘liq",
    memeRatingTitleLocal: "Mahalliy reyting qiymati hisobi. G‘alabalar, mag‘lubiyatlar va ovozlar soniga bog‘liq",
    memeWins: "G‘alabalar soni",
    memeLosts: "Mag‘lubiyatlar soni",
  },
  specials: {
    greetings: "Yangi 2025 Yil bilan!",
    countdown: "Yangi Yilgacha qolgan vaqt",
    days: "kun",
    hours: "soat",
    minutes: "daqiqa",
    seconds: "soniya",
    letsWait: "Kuting 🎄"
  },
  apiError: {
    _400: {
      invalidTemplateForm: "Shablon shakli noto‘g‘ri",
      invalidBirthDate: "Tug‘ilgan sana noto‘g‘ri",
      imageMissing: "Rasm yetishmaydi",
      emailIsNotValid: "Email noto‘g‘ri yoki bunday email bilan foydalanuvchi mavjud",
    },
    _401: {
      wrongLoginPwd: "Login va/yoki parol noto‘g‘ri",
      wrongPwd: "Parol noto‘g‘ri",
    },
    _403: {
      accountNotVerified: "Akkountingiz tasdiqlanmagan",
      accessDenied: "Ruxsat berilmagan",
    },
    _404: {
      notFound: "So‘ralgan resurs topilmadi",
      memeNotFound: "Mem topilmadi",
      userNotFound: "Foydalanuvchi topilmadi",
      templateNotFound: "Shablon topilmadi",
      langNotSupported: "Til qo‘llab-quvvatlanmaydi",
      regNotSupported: "Region mavjud emas yoki qo‘llanmaydi",
      activationLink: "Akkount aktivatsiya havolasi noto‘g‘ri yoki muddati o‘tgan",
      pwdRestoreLink: "Parolni tiklash havolasi noto‘g‘ri yoki muddati tugagan",
      unknownReportCategory: "Noma‘lum shikoyat turi",
    },
    _409: {
      cantJudge: "Ushbu juftlik baholash uchun mavjud emas",
      emailExists: "Ushbu email bilan foydalanuvchi avval ro‘yxatdan o‘tgan",
      nicknameExists: "Ushbu taxallus bilan foydalanuvchi mavjud",
      alreadyReported: "Siz bu post haqida shikoyat qilgansiz",
    },
    _413: {
      imgTooLarge: "Rasm hajmi 10MB dan oshmasligi kerak",
      stringBodyTooLarge: "Matn hajmi 10KB dan oshmasligi kerak",
    },
    _422: {
      imgFileType: "Yuborilgan fayl kengaytmasi yoki formati qo‘llanmaydi",
      imgRatio: "Rasm juda cho‘zilgan, o‘lchamlari 10:1 dan oshmasligi kerak",
      lang: "Tanlangan til qo‘llab-quvvatlanmaydi",
      reg: "Tanlangan region qo‘llab-quvvatlanmaydi",
      userCreation: {
        birthDate: "Yoshingiz 12 dan kam, 100 dan ko‘p bo‘lmasligi lozim",
        email: "Email formati noto‘g‘ri",
        firstName: "Ism uzunligi 100 belgidan oshmasin",
        lastName: "Familiya uzunligi 100 belgidan oshmasin",
        nickname: "Taxallus uzunligi 3-20 belgi, faqat lotin harflar va raqamlar bo‘lishi, faqat raqamlardan iborat bo‘lmasligi kerak",
        password: "Parol uzunligi 6 dan 20 belgigacha bo‘lishi kerak",
      },
      memeOrTemplateCreation: {
        titles: {
          memeTitles: "Matnsiz faqat tarjimabop bo‘lmagan mem yaratiladi",
          tmplTitles: "Shablonni matnsiz yaratib bo‘lmaydi",
          font_size: "Shrift juda katta yoki juda kichik",
          text: "Matn bo‘sh bo‘lmasligi va 2000 belgidan oshmasligi kerak",
          angle: "Matn burchagi noto‘g‘ri",
          textColor: "Matn rangi noto‘g‘ri",
          xRatio: "Matn rasmning vertikal chegarasidan tashqariga chiqyapti",
          yRatio: "Matn rasmning gorizontal chegarasidan tashqariga chiqyapti",
        },
        templateAddon: {
          form: "Mem yoki shablon shakli noto‘g‘ri",
          tags: "Teglar bo‘sh bo‘lmasligi va takror bo‘lmasligi kerak. Umumiy belgilar soni 100 dan oshmasin",
        },
      }
    },
    _429: {
      creationLimit: "Post cheklovi oshib ketdi. Kuniga 10 tagacha mem va 10 ta shablon",
      emailSendingLimit: "Email jo‘natish cheklovi oshib ketdi. Bir daqiqada 1 ta xat, haftasiga 10 tagacha xat",
    },
  },
  navbar: {
    hotMemes: "Qizib ketgan",
    topMemeDevs: "Top mualliflar",
    battleField: "Jang maydoni",
    builder: "Konstruktor",

    localeSettingsTitle: "Mem tili",
    additional: "Qo‘shimcha",
    support: "Texnik yordam",
  },
  report: {
    popUpTitle: "Shikoyat qilish",
    modalTitle: "Shikoyat sababini tanlang",
    spam: "Spam",
    violenceOrHostility: "Zo‘ravonlik yoki adovat",
    adultContent: "Kattalar kontenti",
    other: "Boshqa",
    translation: "Noto‘g‘ri tarjima",
    position: "Noto‘g‘ri matn joylashuvi",
    successSending: "Shikoyat yuborildi",
  },
  authForm: {
    login: {
      title: "Kirish",
      email: "Email",
      password: "Parol",
      forgotPwd: "Parolni unutdingizmi?",
      restorePwd: "Parolni tiklash",
    },
    signUp: {
      title: "Ro‘yxatdan o‘tish",
      nickname: "Taxallus (lotin harflar va raqamlar)",
      firstname: "Ism",
      lastname: "Familiya",
      birthDate: "Tug‘ilgan sana",
      birthDateTooltip: "Bu sizga eng yaxshi memlarni tavsiya qilishda yordam beradi",
      enterYourAge: "Yoshingizni kiriting",
      yourAge: "Yoshingiz",
      memesLang: "Mem tili",
      region: "Regioningiz",
      email: "Email",
      password: "Parol",
      submitPassword: "Parolni tasdiqlash",
      summoner: "Taklif qilgan ID yoki taxallus",
      summonerTooltip: "Agar do‘stingiz taklif qilgan bo‘lsa, uning taxallusini yoki ID sini kiritishni unutmang",
      agreement: {
        agree: "Men roziman",
        privacy: "maxfiylik siyosati",
        and: "va",
        terms: "foydalanish shartlari",
        ending: ""
      },
      agreementModal: {
        title: "Foydalanuvchi shartnomasi",
        byStaying: "Saytda qolish orqali siz",
        privacy: "maxfiylik siyosati",
        and: "va",
        terms: "foydalanish shartlarini",
        ending: " qabul qilasiz",
        acceptBtn: "Qabul qilaman"
      },
      successSignUp: "Ko‘rsatilgan email ga akkauntni tasdiqlash havolasi yuborildi",
      error: {
        pwdDontMatch: "Kiritilgan parollar mos kelmaydi",
        age: "Ro‘yxatdan o‘tish uchun kamida 12 va ko‘pi bilan 100 yosh bo‘lishi kerak",
      }
    },
  },
  profile: {
    premium: {
      premiumAccount: "Premium akkaunt",
      daysLeft: "Premium tugashiga qolgan kunlar: ",
      monthsLeft: "Premium tugashiga qolgan oylar: "
    },
    popUp: {
      myProfile: "Profilim",
      settings: "Sozlamalar",
      logout: "Chiqish",
    },
    public: {
      wins: "G‘alabalar",
      defeats: "Mag‘lubiyatlar",
      judged: "Baholagan",
      position: "O‘rin",
      memesCount: "Memlar",
      rating: "Reyting",
    },
    level: {
      l1: "Junior Mem-Yaratuvchi",
      l2: "Middle Mem-Yaratuvchi",
      l3: "Senior Mem-Yaratuvchi",
      l4: "Mem-Guru",
      l5: "Mem-Xudoga yaqin",
      l6: "Mem-Xudo",
      maxGradeReached: "Eng yuqori daraja egallandi!",
      nextGrade: "Keyingi daraja:",
      completedConditions: "Bajarilgan shartlar:",
      unfulfilledConditions: "Bajarilmagan shartlar:",
    },
    memes: {
      title: "Muallif memlari",
      sort: {
        firstFresh: "Avval yangilar",
        firstOld: "Avval eskilar",
        ratingDown: "Reyting pasayishiga ko‘ra",
        ratingUp: "Reyting oshishiga ko‘ra",
      },
      areYouSureDeleteMeme: "Memni o‘chirib tashlamoqchimisiz?",
      successDeletion: "Mem o‘chirildi",
    },
    badges: {
      percentOfPlayersEarned: "%v foydalanuvchilar ushbu yutuqqa ega",
      nextLevel: "Keyingi daraja",
    },
    settings: {
      title: "Profil sozlamalari",
      nicknameDesc: "Taxallusni o‘zgartirish",
      selectAvatar: "Avatar tanlash",
      avatarUpdated: "Avatar muvaffaqiyatli yangilandi",
    },
    advancedSettings: {
      title: "Sozlamalar",
      locale: {
        title: "Mahalliy sozlama",
        memesLangName: "Til",
        memesLangDesc: "Mem tilini o‘zgartirish",
        regName: "Region",
        regDesc: "Geografik regionni o‘zgartirish",
      },
      security: {
        title: "Xavfsizlik",
        emailName: "Email",
        emailDesc: "Sizning emailingiz",
        pwdName: "Parol",
        changePwd: "Parolni o‘zgartirish",
        fullLogoutName: "Chiqish",
        fullLogoutDesc: "Barcha qurilmalardan chiqish",
        enterCurrentPwd: "Joriy parolni kiriting",
        enterNewPwd: "Yangi parol kiriting",
        pwdUpdateOrRestoreSuccess: "Parol o‘zgartirildi",
        enterEmailToRestorePwd: "Akkountingiz ro‘yxatdan o‘tgan emailni kiriting",
        sendRestoreLink: "Tiklash havolasini yuborish",
        emailToRestorePwdSent: "Ko‘rsatilgan email ga tiklash havolasi yuborildi",
      },
      blackList: {
        title: "Qora ro‘yxat",
        clearBlackList: "Qora ro‘yxatni tozalash",
        clearBlockedTagsSubmitDesc: "Bloklangan teglar ro‘yxatini tozalashni xohlaysizmi?",
        clearBlockedMemeDevsSubmitDesc: "Bloklangan foydalanuvchilar ro‘yxatini tozalashni xohlaysizmi?",
        blockedTagsName: "Bloklangan teglar",
        noBlockedTags: "Bloklangan teglar yo‘q",
        blockedMemeDevsName: "Bloklangan mem yaratuvchilar",
        noBlockedMemeDevs: "Bloklangan yaratuvchilar yo‘q",
      },
      additional: {
        title: "Qo‘shimcha",
        deleteAccount: "Akkountni o‘chirish",
        restoreAccount: "Akkountni tiklash",
        deleteAccountAttentionTitle: "Akkountni o‘chirmoqchimisiz?",
        deleteAccountAttentionDesc: "14 kun ichida o‘chirishni bekor qilish mumkin. Shundan so‘ng akkount butunlay o‘chirib tashlanadi. Shaxsiy ma‘lumotlaringiz ham o‘chirilib ketadi. Yaratilgan memlaringiz anonim bo‘lib qoladi.",
        acceptAccountDeletion: "O‘chirish",
        accountDeletionMsg: "Akkountingiz %v kundan so‘ng o‘chiriladi",
        accountDeletionCanceledMsg: "Akkountni o‘chirishni bekor qildingiz",
        summoner: "Sizni taklif qilgan foydalanuvchi",
      },
    },
    verification: {
      success: "Akkountingiz tasdiqlandi",
      bannerMsg: "Akkount tasdiqlanmagan",
      bannerBtn: "Sozlamalarga o‘tish",
      settings: {
        title: "Akkountni tasdiqlash",
        accountNotVerifiedMsg: "Akkount tasdiqlanmagan. Iltimos, emailingizni kiritib, uni tasdiqlang",
        sendEmail: "Emailni tasdiqlash",
        emailIsSent: "Akkountni tasdiqlash havolasi emailingizga yuborildi",
        nextEmailRemaining: "Qayta jo‘natishdan oldin kuting.\nQolgan (soniya):",
      }
    },
  },
  topMemes: {
    title: "Siz uchun tanlangan issiq memlar",
    loginToSeeMore: "Ko‘proq memlarni ko‘rish uchun kirish qiling",
  },
  duncanChest: {
    title: "Hafta uchun tanlangan memlar",
  },
  battleField: {
    title: "G‘olibni tanlang",
    left: "Chap",
    right: "O‘ng",
    fullScreen: "Butun ekran rejimiga o‘tish",
    collapse: "Yig‘ish",
    allBattlesJudged: "Hozirgi barcha janglar baholandi",
    signUpInfo: "Ro‘yxatdan o‘ting va memlar turli qurilmalarda takrorlanmaydi",
  },
  builder: {
    searchTmpl: {
      filterByForm: "Shakl bo‘yicha filtrlash",
      searchByTags: "Teglar bo‘yicha qidirish",
      noSearchResults: "Hech narsa topilmadi",
      expandPreviewList: "Butun ekranga kengaytirish",
      collapsePreviewList: "Yig‘ish",
    },
    meme: {
      originalLang: "Asl til",
      create: "Mem yaratish",
      creationDesc: "Yangi mem yaratish rejimi",
      editableTitlePlaceholder: "Matn kiriting...",
      titlePlaceholder: "Qiziqarli matn",
      headlineTitlePlaceholder: "Sarlavha (ixtiyoriy)",
      untranslatable: "Tarjima qilib bo‘lmaydigan mem",
      saveAsTmpl: "Shablon sifatida saqlash",
      upload: "Eng zo‘r memni yarating!",
      successCreation: "Mem muvaffaqiyatli yuklandi!",
      validation: {
        emptyTitle: "Bo‘sh sarlavhani to‘ldiring yoki olib tashlang",
      }
    },
    tmpl: {
      create: "Yangi shablon yaratish",
      creationDesc: "Boshqa yaratuvchilar foydalanishi uchun yangi shablon yarating",
      titlePlaceholder: "Qiziqarli matn",
      upload: "Zo‘r shablon yarating!",
      successCreation: "Shablon muvaffaqiyatli yaratildi!",
    },
    selectImage: "Rasm tanlang",
    selectMediaFile: "Rasm, GIF yoki video",
    selectForm: "Shakl tanlang",
    pleaseSelectNewImage: "Iltimos, yangi rasm tanlang",
    appendTitle: "Matn qo‘shish",
    enterTags: "Teglarni probel bilan ajratib kiriting:",
    tagsPlaceholder: "TagMa'lumot BoshqaTag...",
  },
  support: {
    contactUsViaEmail: "Biz bilan quyidagi manzilga email yozish orqali bog‘lanishingiz mumkin: ",
    feelFreeToWriteUs: "Xato topsangiz yoki takliflaringiz bo‘lsa, bemalol yozing. Biz sizning fikringizdan mamnun bo‘lamiz!"
  }
} as const;
