export const translationsES = {
    common: {
        and: "y",
        or: "o",
    },
    ui: {
        defErrMsg: "Algo salió mal :(",
        notFoundErrMsg: "404. Página no encontrada",
        loading: "Cargando...",
        downloadMsg: "La descarga está por comenzar.",
        linkCopied: "Enlace copiado al portapapeles",
        pending: "Pendiente...",
        goToWebsite: "Ir al sitio web",
        localMeme: "Meme local",
        delete: "Eliminar",
        submit: "Confirmar",
        save: "Guardar",
        cancel: "Cancelar",
        tag: {
            showTags: "Mostrar etiquetas",
            hideTags: "Ocultar etiquetas",
            clickToBlock: "Haga clic para agregar etiqueta a la lista negra",
            clickToUnblock: "Haga clic para desbloquear una etiqueta de la lista negra",
            tagBlocked: "La etiqueta está bloqueada. Los memes con esta etiqueta serán excluidos de la colección",
            tagUnblocked: "La etiqueta está desbloqueada"
        },
        memeDev: {
            block: "Bloquear",
            unblock: "Desbloquear",
            memeDevBlocked: "El usuario está bloqueado. Su contenido será excluido de la colección",
            memeDevUnblocked: "El usuario está desbloqueado",
        },
        betaInfo: "Estimado usuario,\n¡muchas gracias por participar en la prueba beta! Si encuentra un error o tiene ideas para mejorar, escríbanos por correo electrónico:",
        mobileMarketNoticeTitle: "Nuestra aplicación está disponible en tiendas:",
        cookieAttention: {
            title: "Utilizamos cookies",
            info: "Al utilizar nuestro sitio usted acepta el uso de cookies. Utilizamos cookies para autorización. Esto nos ayuda a garantizar que el sitio web sea fácil de usar.",
            understood: "Entiendo",
        },
        featureTemporarilyDisabled: "Esta función está temporalmente deshabilitada, estamos trabajando en ello",
        memeRatingTitleGlobal: "Valor de calificación global estimado. Depende del número de victorias, derrotas y número total de votos",
        memeRatingTitleLocal: "Valor de calificación local calculado. Depende del número de victorias, derrotas y número total de votos",
        memeWins: "Número de victorias",
        memeLosts: "Número de pérdidas",
    },
    specials: {
        greetings: "¡Feliz Año Nuevo 2025!",
        countdown: "Tiempo restante para el Año Nuevo",
        days: "días",
        hours: "horas",
        minutes: "minutos",
        seconds: "segundos",
        letsWait: "Esperando 🎄"
    },
    apiError: {
        _400: {
            invalidTemplateForm: "Formulario de plantilla no válido",
            invalidBirthDate: "Fecha de nacimiento no válida",
            imageMissing: "Imagen faltante",
            emailIsNotValid: "El correo electrónico no es válido o ya existe un usuario con el mismo correo electrónico",
        },
        _401: {
            wrongLoginPwd: "Usuario y/o contraseña incorrectos",
            wrongPwd: "Contraseña incorrecta",
        },
        _403: {
            accountNotVerified: "Su cuenta no está verificada",
            accessDenied: "Acceso denegado",
        },
        _404: {
            notFound: "No se encontró el recurso solicitado",
            memeNotFound: "No se encontró el meme",
            userNotFound: "No se encontró al usuario",
            templateNotFound: "No se encontró la plantilla",
            langNotSupported: "Idioma no soportado",
            regNotSupported: "La región no existe o no está soportada",
            activationLink: "El enlace de activación de la cuenta no es válido o ha expirado",
            pwdRestoreLink: "El enlace de recuperación de contraseña es incorrecto o ha caducado",
            unknownReportCategory: "Categoría de informe desconocida",
        },
        _409: {
            cantJudge: "Esta pareja de memes no está disponible para juzgar",
            emailExists: "Ya existe un usuario con el correo electrónico proporcionado",
            nicknameExists: "Ya existe un usuario con el apodo proporcionado",
            alreadyReported: "Ya has informado sobre esta publicación",
        },
        _413: {
            imgTooLarge: "El tamaño del archivo de imagen no debe exceder los 10 MB",
            stringBodyTooLarge: "El tamaño de los datos de texto no debe exceder los 10 KB",
        },
        _422: {
            imgFileType: "La extensión o formato del archivo transferido no es compatible",
            imgRatio: "La imagen está demasiado estirada, la relación de aspecto no debe exceder 10 a 1",
            lang: "El idioma seleccionado no está soportado",
            reg: "La región seleccionada no está soportada",
            userCreation: {
                birthDate: "Tu edad debe estar comprendida entre los 12 y los 100 años",
                email: "El correo electrónico debe tener un formato válido",
                firstName: "La longitud del nombre no debe exceder los 100 caracteres",
                lastName: "La longitud del apellido no debe exceder los 100 caracteres",
                nickname: "La longitud del apodo debe estar entre 3 y 20 caracteres, debe contener solo letras latinas y dígitos, no puede consistir solo en dígitos",
                password: "La longitud de la contraseña debe tener entre 6 y 20 caracteres",
            },
            memeOrTemplateCreation: {
                titles: {
                    memeTitles: "Sin títulos solo puedes crear un meme intraducible",
                    tmplTitles: "No puedes crear una plantilla sin inscripciones",
                    font_size: "El tamaño de fuente es demasiado grande o demasiado pequeño",
                    text: "El título no debe estar vacío y no debe ser más largo de 2000 caracteres",
                    angle: "Ángulo de título no válido",
                    textColor: "Color de título no válido",
                    xRatio: "El título sobresale más allá de los bordes verticales de la imagen",
                    yRatio: "El título sobresale más allá de los bordes horizontales de la imagen",
                },
                templateAddon: {
                    form: "Meme o formulario de plantilla no válido",
                    tags: "Las etiquetas no deben estar vacías y no deben tener duplicados. El número total de caracteres no debe ser superior a 100",
                },
            }
        },
        _429: {
            creationLimit: "Límite de publicaciones superado. No hay más de 10 publicaciones de memes y no más de 10 plantillas disponibles por día",
            emailSendingLimit: "Se ha excedido el límite de envío de correos electrónicos. No hay más de 1 carta cada minuto y no más de 10 cartas cada semana disponibles",
        },
    },
    navbar: {
        hotMemes: "Caliente",
        topMemeDevs: "Autores principales",
        battleField: "Batalla",
        builder: "Constructor",

        localeSettingsTitle: "Idioma meme",
        additional: "Adicional",
        support: "Soporte de contacto",
    },
    report: {
        popUpTitle: "Reportar",
        modalTitle: "Seleccione el motivo de la queja",
        spam: "Spam",
        violenceOrHostility: "Violencia o hostilidad",
        adultContent: "Contenido para adultos",
        other: "Otro",
        translation: "Traducción incorrecta",
        position: "Posicionamiento incorrecto",
        successSending: "Queja enviada",
    },
    authForm: {
        login: {
            title: "Iniciar sesión",
            email: "Correo electrónico",
            password: "Contraseña",
            forgotPwd: "¿Olvidaste tu contraseña?",
            restorePwd: "Restaurar contraseña",
        },
        signUp: {
            title: "Registrarse",
            nickname: "Apodo (letras latinas y dígitos)",
            firstname: "Nombre",
            lastname: "Apellido",
            birthDate: "Fecha de nacimiento",
            birthDateTooltip: "Esto nos ayudará a recomendarte los mejores memes",
            enterYourAge: "Introduzca su edad",
            yourAge: "Su edad",
            memesLang: "Idioma de memes",
            region: "Tu región",
            email: "Correo electrónico",
            password: "Contraseña",
            submitPassword: "Confirmar contraseña",
            summoner: "ID o apodo del invitador",
            summonerTooltip: "Si fuiste invitado por un amigo, no olvides ingresar su apodo o Id",
            agreement: {
                agree: "Estoy de acuerdo con la",
                privacy: "política de privacidad",
                and: "y los",
                terms: "términos de uso",
                ending: ""
            },
            agreementModal: {
                title: "Acuerdo de usuario",
                byStaying: "Al permanecer en el sitio, aceptas la",
                privacy: "política de privacidad",
                and: "y los",
                terms: "términos de uso",
                ending: "",
                acceptBtn: "Acepto"
            },
            successSignUp: "Se ha enviado un correo electrónico con un enlace para verificar su cuenta a la dirección especificada",
            error: {
                pwdDontMatch: "Las contraseñas ingresadas no coinciden",
                age: "Para registrarte debes tener al menos 12 años y no más de 100 años.",
            }
        },
    },
    profile: {
        premium: {
            premiumAccount: "Cuenta privilegiada",
            daysLeft: "Días hasta que finalice el estado premium: ",
            monthsLeft: "Meses hasta que finalice el estado premium: "
        },
        popUp: {
            myProfile: "Mi perfil",
            settings: "Ajustes",
            logout: "Cerrar sesión",
        },
        public: {
            wins: "Victorias",
            defeats: "Derrotas",
            judged: "Valorado",
            position: "Posición",
            memesCount: "Memes",
            rating: "Calificación",
        },
        level: {
            l1: "Desarrollador de Memes Junior",
            l2: "Desarrollador de Memes Intermedio",
            l3: "Desarrollador de Memes Senior",
            l4: "Gurú Desarrollador de Memes",
            l5: "Casi un Dios de Memes",
            l6: "Dios de Memes",
            maxGradeReached: "¡Nivel máximo alcanzado!",
            nextGrade: "Siguiente nivel:",
            completedConditions: "Condiciones completadas:",
            unfulfilledConditions: "Condiciones no cumplidas:",
        },
        memes: {
            title: "Memes del autor",
            sort: {
                firstFresh: "Primero fresco",
                firstOld: "Primero antiguo",
                ratingDown: "Calificación descendente",
                ratingUp: "Calificación ascendente",
            },
            areYouSureDeleteMeme: "¿Estás seguro de que quieres eliminar el meme?",
            successDeletion: "Meme eliminado",
        },
        badges: {
            percentOfPlayersEarned: "El %v de los jugadores tiene este logro",
            nextLevel: "Próximo nivel",
        },
        settings: {
            title: "Ajustes del perfil",
            nicknameDesc: "Cambiar apodo",
            selectAvatar: "Selecciona un avatar",
            avatarUpdated: "El avatar se ha actualizado correctamente",
        },
        advancedSettings: {
            title: "Ajustes",
            locale: {
                title: "Configuración regional",
                memesLangName: "Idioma",
                memesLangDesc: "Cambiar idioma de memes",
                regName: "Región",
                regDesc: "Cambiar región geográfica",
            },
            security: {
                title: "Seguridad",
                emailName: "Correo electrónico",
                emailDesc: "Tu correo electrónico",
                pwdName: "Contraseña",
                changePwd: "Cambiar contraseña",
                fullLogoutName: "Cerrar sesión",
                fullLogoutDesc: "Cerrar sesión en todos los dispositivos",
                enterCurrentPwd: "Ingrese la contraseña actual",
                enterNewPwd: "Introduzca una nueva contraseña",
                pwdUpdateOrRestoreSuccess: "Tu contraseña ha sido cambiada",
                enterEmailToRestorePwd: "Ingrese el correo electrónico en el que está registrada su cuenta",
                sendRestoreLink: "Enviar enlace para restauración",
                emailToRestorePwdSent: "Se ha enviado un enlace de recuperación al correo electrónico especificado",
            },
            blackList: {
                title: "Lista negra",
                clearBlackList: "Borrar lista negra",
                clearBlockedTagsSubmitDesc: "¿Está seguro de que desea borrar la lista de etiquetas bloqueadas?",
                clearBlockedMemeDevsSubmitDesc: "¿Estás seguro de que deseas borrar la lista de usuarios bloqueados?",
                blockedTagsName: "Etiquetas bloqueadas",
                noBlockedTags: "No hay etiquetas bloqueadas",
                blockedMemeDevsName: "Desarrolladores de memes bloqueados",
                noBlockedMemeDevs: "No hay desarrolladores de memes bloqueados",
            },
            additional: {
                title: "Adicional",
                deleteAccount: "Eliminar cuenta",
                restoreAccount: "Restaurar cuenta",
                deleteAccountAttentionTitle: "¿Quieres eliminar tu cuenta?",
                deleteAccountAttentionDesc: "Puedes cancelar la eliminación de la cuenta dentro de los 14 días. Después de este período, tu cuenta se eliminará permanentemente. Tus datos personales se eliminarán. Los memes que crees se volverán anónimos.",
                acceptAccountDeletion: "Eliminar",
                accountDeletionMsg: "Su cuenta será eliminada en %v días",
                accountDeletionCanceledMsg: "Has cancelado la eliminación de la cuenta",
                summoner: "La persona que te invitó",
            },
        },
        verification: {
            success: "Tu cuenta ha sido verificada",
            bannerMsg: "Tu cuenta no está verificada",
            bannerBtn: "Ir a ajustes",
            settings: {
                title: "Verificación de cuenta",
                accountNotVerifiedMsg: "Su cuenta no ha sido verificada. Por favor indica y confirma tu email",
                sendEmail: "Confirmar correo electrónico",
                emailIsSent: "Se ha enviado un correo electrónico a tu correo electrónico para confirmar tu cuenta",
                nextEmailRemaining: "Para reenviar el correo electrónico, por favor espera.\nRestante (segundos):",
            },
        },
    },
    topMemes: {
        title: "Memes calientes seleccionados para ti",
        loginToSeeMore: "Inicia sesión para ver más memes",
    },
    duncanChest: {
        title: "Memes seleccionados para esta semana",
    },
    battleField: {
        title: "Elige un ganador",
        left: "Izquierda",
        right: "Derecha",
        fullScreen: "Entrar en modo de pantalla completa",
        collapse: "Colapsar",
        allBattlesJudged: "Usted ha juzgado en todas las batallas disponibles hasta el momento",
        signUpInfo: "Regístrate y los memes no se repetirán en diferentes dispositivos",
    },
    builder: {
        searchTmpl: {
            filterByForm: "Filtrar por forma",
            searchByTags: "Buscar por etiquetas",
            noSearchResults: "La búsqueda no ha dado ningún resultado",
            expandPreviewList: "Ampliar a pantalla completa",
            collapsePreviewList: "Colapsar",
        },
        meme: {
            originalLang: "Idioma original",
            create: "Crear meme",
            creationDesc: "Modo de creación de nuevo meme",
            editableTitlePlaceholder: "Ingrese texto...",
            titlePlaceholder: "Algo gracioso",
            headlineTitlePlaceholder: "Título (opcional)",
            untranslatable: "Meme intraducible",
            saveAsTmpl: "Guardar como plantilla",
            upload: "¡Crea el mejor meme!",
            successCreation: "¡El meme se ha subido correctamente!",
            validation: {
                emptyTitle: "Complete o elimine etiquetas en blanco",
            }
        },
        tmpl: {
            create: "Crear nueva plantilla",
            creationDesc: "Crea una nueva plantilla que estará disponible para otros creadores",
            titlePlaceholder: "Texto gracioso",
            upload: "¡Crea una plantilla increíble!",
            successCreation: "¡La plantilla se ha creado correctamente!",
        },
        selectImage: "Seleccionar imagen",
        selectMediaFile: "Imagen, gif o vídeo",
        selectForm: "Seleccionar forma",
        pleaseSelectNewImage: "Por favor, selecciona una nueva imagen",
        appendTitle: "Añadir título",
        enterTags: "Ingrese etiquetas separadas por espacios:",
        tagsPlaceholder: "TagInformativo OtroTag...",
    },
    support: {
        contactUsViaEmail: "Puede contactar con nosotros escribiendo un correo electrónico a la siguiente dirección: ",
        feelFreeToWriteUs: "Si encuentra un error o tiene ideas para mejorar, no dude en escribirnos. ¡Estaremos muy felices de recibir tus comentarios!"
    }
};