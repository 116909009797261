export const latinCyrillicFonts = [
    'arial black',
    'comic sans ms',
    'impact',
    'roboto',
    'times new roman',
    'lobster'
] as const;

export const hiFonts = [
    'hind bold',
    'mangal',
    'mangal bold',
    'nirmala ui'
] as const;

export const kkFonts = [
    'caveat',
    'marmelad',
    'oswald',
    'pacifico',
    'roboto' // suitable for Kazakh
] as const;

export const zhFonts = [
    'liu jian mao cao',
    'noto sans sc',
    'zcool kuai le',
    'zcool xiao wei',
    'zhi mang xing'
] as const;

export const jaFonts = [
    'dela gothic one',
    'dot gothic16',
    'mochiy pop one',
    'm plus rounded1c',
    'noto serif jp'
] as const;

// getFontListByLang returns latinCyrillicFonts by default
export function getFontListByLang(langCode: string) {
    if (langCode === "hi") {
        return hiFonts
    }
    if (langCode === "kk") {
        return kkFonts
    }
    if (langCode === "zh") {
        return zhFonts
    }
    if (langCode === "ja") {
        return jaFonts
    }

    return latinCyrillicFonts
}