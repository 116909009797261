export const translationsKK = {
  common: {
    and: "және",
    or: "немесе",
  },
  ui: {
    defErrMsg: "Бір нәрсе дұрыс болмай қалды :(",
    notFoundErrMsg: "404. Бет табылмады",
    loading: "Жүктелуде...",
    downloadMsg: "Жүктеу қазір басталады",
    linkCopied: "Сілтеме аралық сақтағышқа көшірілді",
    pending: "Күту...",
    goToWebsite: "Сайтқа өту",
    localMeme: "Жергілікті мем",
    delete: "Жою",
    submit: "Растау",
    save: "Сақтау",
    cancel: "Болдырмау",
    tag: {
      showTags: "Тэгтерді көрсету",
      hideTags: "Тэгтерді жасыру",
      clickToBlock: "Тэгті қара тізімге қосу үшін басыңыз",
      clickToUnblock: "Тэгті қара тізімнен алу үшін басыңыз",
      tagBlocked: "Тэг бұғатталды. Осы тэгі бар мемдер іріктеуден шығарылады",
      tagUnblocked: "Тэг бұғаттан босатылды"
    },
    memeDev: {
      block: "Бұғаттау",
      unblock: "Бұғаттан шығару",
      memeDevBlocked: "Пайдаланушы бұғатталды. Оның контенті іріктеуден шығарылады",
      memeDevUnblocked: "Пайдаланушы бұғаттан босатылды",
    },
    betaInfo: "Құрметті пайдаланушы,\nбета-тестке қатысқаныңыз үшін көп рахмет! Егер қате тапсаңыз немесе идеяларыңыз болса, бізге поштаға жазыңыз:",
    mobileMarketNoticeTitle: "Біздің қолданба қолжетімді дүкендерде:",
    cookieAttention: {
      title: "Біз cookie-файлдарды қолданамыз",
      info: "Біздің сайтты пайдалана отырып, сіз cookie-файлдарды пайдалануға келісім бересіз. Авторландыру үшін cookie қолданамыз. Бұл сайтты ыңғайлы етуге көмектеседі.",
      understood: "Түсінікті",
    },
    featureTemporarilyDisabled: "Бұл функция уақытша өшірілді, біз оны түзетіп жатырмыз",
    memeRatingTitleGlobal: "Жаһандық рейтинг мәні. Жеңіс, жеңіліс және дауыс санына тәуелді",
    memeRatingTitleLocal: "Жергілікті рейтинг мәні. Жеңіс, жеңіліс және дауыс санына тәуелді",
    memeWins: "Жеңістер саны",
    memeLosts: "Жеңілістер саны",
  },
  specials: {
    greetings: "2025 Жаңа жылыңызбен!",
    countdown: "Жаңа жылға дейін қалған уақыт",
    days: "күн",
    hours: "сағат",
    minutes: "минут",
    seconds: "секунд",
    letsWait: "Күтудеміз 🎄"
  },
  apiError: {
    _400: {
      invalidTemplateForm: "Үлгі пішіні қате",
      invalidBirthDate: "Туған күн қате",
      imageMissing: "Сурет жүктелмеген",
      emailIsNotValid: "Email жарамсыз немесе бұл email тіркелген",
    },
    _401: {
      wrongLoginPwd: "Логин мен/немесе құпиясөз қате",
      wrongPwd: "Құпиясөз қате",
    },
    _403: {
      accountNotVerified: "Есептік жазба расталмаған",
      accessDenied: "Қол жеткізу шектелді",
    },
    _404: {
      notFound: "Сұралған ресурс табылмады",
      memeNotFound: "Мем табылмады",
      userNotFound: "Пайдаланушы табылмады",
      templateNotFound: "Үлгі табылмады",
      langNotSupported: "Тіл қолдау таппайды",
      regNotSupported: "Аймақ қолдау таппайды",
      activationLink: "Активтеу сілтемесі қате немесе уақыты өткен",
      pwdRestoreLink: "Құпиясөз қалпына келтіру сілтемесі қате немесе уақыты өткен",
      unknownReportCategory: "Белгісіз шағым санаты",
    },
    _409: {
      cantJudge: "Бұл мем жұбы бағалауға қолжетімсіз",
      emailExists: "Бұл email бұрынырақ тіркелген",
      nicknameExists: "Мұндай лақап атпен пайдаланушы бар",
      alreadyReported: "Бұл жазбаға шағым жасадыңыз",
    },
    _413: {
      imgTooLarge: "Сурет өлшемі 10MB аспауы керек",
      stringBodyTooLarge: "Мәтін өлшемі 10KB аспауы керек",
    },
    _422: {
      imgFileType: "Файл кеңейтілімі немесе форматы қолдау таппайды",
      imgRatio: "Сурет тым созылған, пропорциясы 10:1 аспау керек",
      lang: "Таңдалған тіл қолдау таппайды",
      reg: "Таңдалған аймақ қолдау таппайды",
      userCreation: {
        birthDate: "Жасыңыз 12-ден 100-ге дейін болуы керек",
        email: "Email форматы дұрыс болу керек",
        firstName: "Есім ұзындығы 100 символдан аспауы керек",
        lastName: "Тегінің ұзындығы 100 символдан аспауы керек",
        nickname: "Лақап ат 3-20 символ, тек латын әріптері мен сандар, түгел сандардан тұра алмайды",
        password: "Құпиясөз ұзындығы 6-20 символ аралығында",
      },
      memeOrTemplateCreation: {
        titles: {
          memeTitles: "Тақырыпсыз тек аударылмайтын мем жасауға болады",
          tmplTitles: "Тақырыпсыз шаблон жасау мүмкін емес",
          font_size: "Қаріп өлшемі тым үлкен не тым кіші",
          text: "Тақырып бос болмауы керек және 2000 таңбадан аспау керек",
          angle: "Тақырып бұрышы дұрыс емес",
          textColor: "Тақырып түсі жарамсыз",
          xRatio: "Тақырып суреттің тігінен шегінен шығып тұр",
          yRatio: "Тақырып суреттің көлденең шегінен шығып тұр",
        },
        templateAddon: {
          form: "Мем немесе шаблон формасы жарамсыз",
          tags: "Тегтер бос болмауы және қайталанбауы тиіс. Барлық таңба саны 100-ден аспауы керек",
        },
      }
    },
    _429: {
      creationLimit: "Публикация шегі асып кетті. Күніне 10 мем және 10 шаблоннан артық емес",
      emailSendingLimit: "Хат жіберу шегі асып кетті. Минутына бір хат, аптасына 10 хаттан артық емес",
    },
  },
  navbar: {
    hotMemes: "Ыстық мемдер",
    topMemeDevs: "Үздік авторлар",
    battleField: "Айқас",
    builder: "Конструктор",

    localeSettingsTitle: "Мем тілі",
    additional: "Қосымша",
    support: "Техқолдау",
  },
  report: {
    popUpTitle: "Шағым беру",
    modalTitle: "Шағым себебін таңдаңыз",
    spam: "Спам",
    violenceOrHostility: "Зорлық немесе араздық",
    adultContent: "Ересектерге арналған контент",
    other: "Басқа",
    translation: "Тақырып аудармасы қате",
    position: "Тақырып орналасуы қате",
    successSending: "Шағым жіберілді",
  },
  authForm: {
    login: {
      title: "Кіру",
      email: "Email",
      password: "Құпиясөз",
      forgotPwd: "Құпиясөзді ұмыттыңыз ба?",
      restorePwd: "Қалпына келтіру",
    },
    signUp: {
      title: "Тіркелу",
      nickname: "Лақап ат (латын әріптері мен сандар)",
      firstname: "Есім",
      lastname: "Тегі",
      birthDate: "Туған күні",
      birthDateTooltip: "Сізге ең қызықты мемдерді ұсынуға көмектеседі",
      enterYourAge: "Жасыңызды көрсетіңіз",
      yourAge: "Жасыңыз",
      memesLang: "Мем тілі",
      region: "Аймағыңыз",
      email: "Email",
      password: "Құпиясөз",
      submitPassword: "Құпиясөзді растау",
      summoner: "Шақырған адамның ID не лақап аты",
      summonerTooltip: "Егер досыңыз шақырған болса, оның никнеймін немесе ID енгізуге ұмытпаңыз",
      agreement: {
        agree: "Мен келісемін",
        privacy: "құпиялылық саясатына",
        and: "және",
        terms: "пайдалану ережесіне",
        ending: ""
      },
      agreementModal: {
        title: "Пайдаланушы келісімі",
        byStaying: "Сайтта қалу арқылы сіз",
        privacy: "құпиялылық саясатын",
        and: "және",
        terms: "пайдалану ережесін",
        ending: " қабылдайсыз",
        acceptBtn: "Қабылдаймын"
      },
      successSignUp: "Көрсетілген email-ге аккаунтты растауға сілтеме жіберілді",
      error: {
        pwdDontMatch: "Енгізілген құпиясөздер сәйкес емес",
        age: "Тіркелу үшін жасыңыз кемінде 12, ең көбі 100 болуы керек",
      }
    },
  },
  profile: {
    premium: {
      premiumAccount: "Премиум аккаунт",
      daysLeft: "Премиум бітуіне күн қалды: ",
      monthsLeft: "Премиум бітуіне ай қалды: "
    },
    popUp: {
      myProfile: "Менің парақшам",
      settings: "Баптаулар",
      logout: "Шығу",
    },
    public: {
      wins: "Жеңістер",
      defeats: "Жеңілістер",
      judged: "Бағалады",
      position: "Орын",
      memesCount: "Мемдер",
      rating: "Рейтинг",
    },
    level: {
      l1: "Жуниор-мем жасаушы",
      l2: "Орташа деңгейлі мем жасаушы",
      l3: "Үлкен мем жасаушы",
      l4: "Мем-гуру",
      l5: "Мем-құдайға жақын",
      l6: "Мем-құдай",
      maxGradeReached: "Ең жоғарғы деңгейге жеттіңіз!",
      nextGrade: "Келесі деңгей:",
      completedConditions: "Орындалған шарттар:",
      unfulfilledConditions: "Орындалмаған шарттар:",
    },
    memes: {
      title: "Авторлық мемдер",
      sort: {
        firstFresh: "Алдымен жаңалар",
        firstOld: "Алдымен ескілер",
        ratingDown: "Рейтинг кемумен",
        ratingUp: "Рейтинг өсуімен",
      },
      areYouSureDeleteMeme: "Осы мемді жоюға сенімдісіз бе?",
      successDeletion: "Мем жойылды",
    },
    badges: {
      percentOfPlayersEarned: "%v ойыншыларда бұл жетістік бар",
      nextLevel: "Келесі деңгей",
    },
    settings: {
      title: "Профиль баптаулары",
      nicknameDesc: "Лақап атты өзгерту",
      selectAvatar: "Аватар таңдау",
      avatarUpdated: "Аватар сәтті жаңартылды",
    },
    advancedSettings: {
      title: "Баптаулар",
      locale: {
        title: "Локаль",
        memesLangName: "Тілі",
        memesLangDesc: "Мем тілін өзгерту",
        regName: "Аймақ",
        regDesc: "Географиялық аймақты өзгерту",
      },
      security: {
        title: "Қауіпсіздік",
        emailName: "Email",
        emailDesc: "Сіздің email",
        pwdName: "Құпиясөз",
        changePwd: "Құпиясөзді өзгерту",
        fullLogoutName: "Шығу",
        fullLogoutDesc: "Барлық құрылғылардан шығу",
        enterCurrentPwd: "Ағымдағы құпиясөзді енгізіңіз",
        enterNewPwd: "Жаңа құпиясөз енгізіңіз",
        pwdUpdateOrRestoreSuccess: "Құпиясөз сәтті жаңартылды",
        enterEmailToRestorePwd: "Аккаунт тіркелген email енгізіңіз",
        sendRestoreLink: "Қалпына келтіру сілтемесін жіберу",
        emailToRestorePwdSent: "Көрсетілген email-ге сілтеме жіберілді",
      },
      blackList: {
        title: "Қара тізім",
        clearBlackList: "Қара тізімді тазарту",
        clearBlockedTagsSubmitDesc: "Бұғатталған тэгтер тізімін тазартуға сенімдісіз бе?",
        clearBlockedMemeDevsSubmitDesc: "Бұғатталған пайдаланушылар тізімін тазартуға сенімдісіз бе?",
        blockedTagsName: "Бұғатталған тэгтер",
        noBlockedTags: "Бұғатталған тэгтер жоқ",
        blockedMemeDevsName: "Бұғатталған мем авторлары",
        noBlockedMemeDevs: "Бұғатталған авторлар жоқ",
      },
      additional: {
        title: "Қосымша",
        deleteAccount: "Аккаунты жою",
        restoreAccount: "Аккаунты қалпына келтіру",
        deleteAccountAttentionTitle: "Аккаунтыңызды жойғыңыз келе ме?",
        deleteAccountAttentionDesc: "14 күн ішінде жоюды болдырмауға болады. Осы мерзімнен кейін аккаунт мүлде жойылады. Жеке мәліметтеріңіз өшіріледі. Жасаған мемдеріңіз анонимді болады.",
        acceptAccountDeletion: "Жою",
        accountDeletionMsg: "Аккаунтыңыз %v күннен кейін жойылады",
        accountDeletionCanceledMsg: "Аккаунты жоюдан бас тарттыңыз",
        summoner: "Сізді шақырған адам",
      },
    },
    verification: {
      success: "Аккаунт расталды",
      bannerMsg: "Аккаунт расталмаған",
      bannerBtn: "Баптауларға өту",
      settings: {
        title: "Аккаунтты растау",
        accountNotVerifiedMsg: "Аккаунтыңыз расталмаған. Поштаңызды көрсетіп, растаңыз",
        sendEmail: "Почтаны растау",
        emailIsSent: "Поштаға растау сілтемесі жіберілді",
        nextEmailRemaining: "Қайталап жіберу үшін күтіңіз.\nҚалғаны (секунд):",
      }
    },
  },
  topMemes: {
    title: "Сіз үшін таңдалған ыстық мемдер",
    loginToSeeMore: "Көбірек көру үшін кіріңіз",
  },
  duncanChest: {
    title: "Осы аптадағы таңдалған мемдер",
  },
  battleField: {
    title: "Жеңімпазды таңдаңыз",
    left: "Сол",
    right: "Оң",
    fullScreen: "Толық экранға көшу",
    collapse: "Бүктеу",
    allBattlesJudged: "Қазіргі қолжетімді барлық айқастарды бағаладыңыз",
    signUpInfo: "Тіркеліп, түрлі құрылғыларда мемдердің қайталанбауына қол жеткізіңіз",
  },
  builder: {
    searchTmpl: {
      filterByForm: "Форма бойынша сүзу",
      searchByTags: "Тэг арқылы іздеу",
      noSearchResults: "Ештеңе табылмады",
      expandPreviewList: "Толық экранға кеңейту",
      collapsePreviewList: "Бүктеу",
    },
    meme: {
      originalLang: "Түпнұсқа тілі",
      create: "Мем жасау",
      creationDesc: "Жаңа мем жасау режимі",
      editableTitlePlaceholder: "Мәтін енгізіңіз...",
      titlePlaceholder: "Әзіл мәтін",
      headlineTitlePlaceholder: "Тақырып (қосымша)",
      untranslatable: "Аударылмайтын мем",
      saveAsTmpl: "Шаблон ретінде сақтау",
      upload: "Жақсы мем жасаңыз!",
      successCreation: "Мем сәтті жүктелді!",
      validation: {
        emptyTitle: "Бос жазуды толтырыңыз немесе өшіріңіз",
      }
    },
    tmpl: {
      create: "Жаңа шаблон жасау",
      creationDesc: "Басқа жасаушыларға қолжетімді болатын шаблон жасаңыз",
      titlePlaceholder: "Күлкілі мәтін",
      upload: "Керемет шаблон жасаңыз!",
      successCreation: "Шаблон сәтті құрылды!",
    },
    selectImage: "Сурет таңдау",
    selectMediaFile: "Сурет, GIF немесе видео",
    selectForm: "Форманы таңдау",
    pleaseSelectNewImage: "Жаңа сурет таңдаңыз",
    appendTitle: "Тақырып қосу",
    enterTags: "Тэгтерді бос орын арқылы енгізіңіз:",
    tagsPlaceholder: "ХабарламалыТэг БасқаТэг...",
  },
  support: {
    contactUsViaEmail: "Бізге келесі адреске хат жазып байланыса аласыз: ",
    feelFreeToWriteUs: "Егер сіз қате тапсаңыз немесе жақсарту туралы ұсыныстарыңыз болса, қорықпай жазыңыз. Біз сіздің пікіріңізге қуаныштымыз!"
  }
} as const;