export const translationsZH = {
  common: {
    and: "和",
    or: "或",
  },
  ui: {
    defErrMsg: "出现问题 :(",
    notFoundErrMsg: "404. 未找到页面",
    loading: "加载中...",
    downloadMsg: "即将开始下载",
    linkCopied: "链接已复制到剪贴板",
    pending: "等待中...",
    goToWebsite: "前往网站",
    localMeme: "本地梗图",
    delete: "删除",
    submit: "确认",
    save: "保存",
    cancel: "取消",
    tag: {
      showTags: "显示标签",
      hideTags: "隐藏标签",
      clickToBlock: "点击将标签加入黑名单",
      clickToUnblock: "点击从黑名单解除此标签",
      tagBlocked: "标签已被屏蔽，带此标签的梗图将被排除",
      tagUnblocked: "标签已解除屏蔽"
    },
    memeDev: {
      block: "屏蔽",
      unblock: "解屏蔽",
      memeDevBlocked: "用户已被屏蔽，其内容将被排除",
      memeDevUnblocked: "用户已解除屏蔽",
    },
    betaInfo: "尊敬的用户，\n非常感谢您参与测试！如果发现错误或有改进建议，请给我们发送电子邮件：",
    mobileMarketNoticeTitle: "我们的应用已上架以下商店：",
    cookieAttention: {
      title: "我们使用 Cookie",
      info: "使用本站即表示您同意使用 Cookie。我们用 Cookie 做授权，确保站点易于使用。",
      understood: "明白了",
    },
    featureTemporarilyDisabled: "该功能暂时停用，我们正在努力修复",
    memeRatingTitleGlobal: "预估全局评分，根据胜负数量和投票总数计算",
    memeRatingTitleLocal: "预估局部评分，根据胜负数量和投票总数计算",
    memeWins: "胜利次数",
    memeLosts: "失败次数",
  },
  specials: {
    greetings: "新年快乐 2025！",
    countdown: "距离新年还有",
    days: "天",
    hours: "小时",
    minutes: "分钟",
    seconds: "秒",
    letsWait: "等待中 🎄"
  },
  apiError: {
    _400: {
      invalidTemplateForm: "模板表单无效",
      invalidBirthDate: "出生日期无效",
      imageMissing: "缺少图片",
      emailIsNotValid: "邮箱无效或已被注册",
    },
    _401: {
      wrongLoginPwd: "登录或密码错误",
      wrongPwd: "密码错误",
    },
    _403: {
      accountNotVerified: "账号未验证",
      accessDenied: "拒绝访问",
    },
    _404: {
      notFound: "请求的资源不存在",
      memeNotFound: "梗图未找到",
      userNotFound: "用户未找到",
      templateNotFound: "模板未找到",
      langNotSupported: "不支持的语言",
      regNotSupported: "地区不存在或不受支持",
      activationLink: "激活链接无效或已过期",
      pwdRestoreLink: "重设密码链接无效或已过期",
      unknownReportCategory: "未知的举报类型",
    },
    _409: {
      cantJudge: "无法评判此梗图对决",
      emailExists: "该邮箱已被注册",
      nicknameExists: "该昵称已被注册",
      alreadyReported: "您已举报过该内容",
    },
    _413: {
      imgTooLarge: "图片文件不得超过 10MB",
      stringBodyTooLarge: "文本数据不得超过 10KB",
    },
    _422: {
      imgFileType: "文件格式或扩展名不受支持",
      imgRatio: "图片过度拉伸，纵横比不可超过 10:1",
      lang: "所选语言不支持",
      reg: "所选地区不支持",
      userCreation: {
        birthDate: "年龄须在 12 到 100 岁之间",
        email: "邮箱格式不正确",
        firstName: "名字长度不得超过 100 个字符",
        lastName: "姓氏长度不得超过 100 个字符",
        nickname: "昵称为 3-20 个字符，仅限字母数字，且不能全是数字",
        password: "密码长度必须在 6-20 之间",
      },
      memeOrTemplateCreation: {
        titles: {
          memeTitles: "没有标题只能创建不可翻译的梗图",
          tmplTitles: "不能没有标题就创建模板",
          font_size: "字体大小过大或过小",
          text: "标题不能为空，且长度不得超过 2000 字符",
          angle: "标题角度无效",
          textColor: "标题颜色无效",
          xRatio: "标题超出图片垂直边界",
          yRatio: "标题超出图片水平边界",
        },
        templateAddon: {
          form: "无效的梗图或模板表单",
          tags: "标签不得为空、不得重复，总字符数不得超过 100",
        },
      }
    },
    _429: {
      creationLimit: "已超过发布限制：每天最多 10 个梗图和 10 个模板",
      emailSendingLimit: "邮件发送频率过高：每分钟 1 封，每周最多 10 封",
    },
  },
  navbar: {
    hotMemes: "热门梗图",
    topMemeDevs: "热门作者",
    battleField: "对决",
    builder: "生成器",

    localeSettingsTitle: "梗图语言",
    additional: "附加",
    support: "联系客服",
  },
  report: {
    popUpTitle: "举报",
    modalTitle: "请选择举报原因",
    spam: "垃圾信息",
    violenceOrHostility: "暴力或敌意",
    adultContent: "成人内容",
    other: "其他",
    translation: "翻译有误",
    position: "标题位置有误",
    successSending: "举报已发送",
  },
  authForm: {
    login: {
      title: "登录",
      email: "邮箱",
      password: "密码",
      forgotPwd: "忘记密码？",
      restorePwd: "找回密码",
    },
    signUp: {
      title: "注册",
      nickname: "昵称 (字母数字)",
      firstname: "名字",
      lastname: "姓氏",
      birthDate: "出生日期",
      birthDateTooltip: "帮助我们向您推荐最佳梗图",
      enterYourAge: "填写您的年龄",
      yourAge: "您的年龄",
      memesLang: "梗图语言",
      region: "您的地区",
      email: "邮箱",
      password: "密码",
      submitPassword: "确认密码",
      summoner: "邀请人ID或昵称",
      summonerTooltip: "若朋友邀请你，别忘了填TA的昵称或ID",
      agreement: {
        agree: "我已阅读",
        privacy: "隐私政策",
        and: "和",
        terms: "使用条款",
        ending: ""
      },
      agreementModal: {
        title: "用户协议",
        byStaying: "继续使用本站即表示你接受",
        privacy: "隐私政策",
        and: "和",
        terms: "使用条款",
        ending: "",
        acceptBtn: "同意"
      },
      successSignUp: "验证链接已发送到你的邮箱",
      error: {
        pwdDontMatch: "两次输入的密码不一致",
        age: "注册需要年龄在 12~100 岁之间",
      }
    },
  },
  profile: {
    premium: {
      premiumAccount: "高级账号",
      daysLeft: "剩余高级状态天数：",
      monthsLeft: "剩余高级状态月份："
    },
    popUp: {
      myProfile: "我的资料",
      settings: "设置",
      logout: "退出登录",
    },
    public: {
      wins: "胜利",
      defeats: "失败",
      judged: "评价次数",
      position: "排行",
      memesCount: "梗图",
      rating: "评分",
    },
    level: {
      l1: "初级梗图开发者",
      l2: "中级梗图开发者",
      l3: "高级梗图开发者",
      l4: "梗图大师",
      l5: "准梗图之神",
      l6: "梗图之神",
      maxGradeReached: "已达最高等级！",
      nextGrade: "下一级：",
      completedConditions: "已完成条件：",
      unfulfilledConditions: "未完成条件：",
    },
    memes: {
      title: "作者梗图",
      sort: {
        firstFresh: "先新的",
        firstOld: "先旧的",
        ratingDown: "评分降序",
        ratingUp: "评分升序",
      },
      areYouSureDeleteMeme: "确定删除该梗图？",
      successDeletion: "梗图已删除",
    },
    badges: {
      percentOfPlayersEarned: "已有 %v 的玩家获得此成就",
      nextLevel: "下一等级",
    },
    settings: {
      title: "个人资料设置",
      nicknameDesc: "修改昵称",
      selectAvatar: "选择头像",
      avatarUpdated: "头像已更新",
    },
    advancedSettings: {
      title: "设置",
      locale: {
        title: "本地化",
        memesLangName: "语言",
        memesLangDesc: "更改梗图语言",
        regName: "地区",
        regDesc: "更改地区设置",
      },
      security: {
        title: "安全",
        emailName: "邮箱",
        emailDesc: "你的邮箱",
        pwdName: "密码",
        changePwd: "修改密码",
        fullLogoutName: "退出登录",
        fullLogoutDesc: "从所有设备退出",
        enterCurrentPwd: "输入当前密码",
        enterNewPwd: "输入新密码",
        pwdUpdateOrRestoreSuccess: "密码已更新",
        enterEmailToRestorePwd: "输入账号注册的邮箱",
        sendRestoreLink: "发送重置链接",
        emailToRestorePwdSent: "重置链接已发送到指定邮箱",
      },
      blackList: {
        title: "黑名单",
        clearBlackList: "清空黑名单",
        clearBlockedTagsSubmitDesc: "确定要清除被屏蔽的标签吗？",
        clearBlockedMemeDevsSubmitDesc: "确定要清除被屏蔽的用户吗？",
        blockedTagsName: "已屏蔽的标签",
        noBlockedTags: "无屏蔽标签",
        blockedMemeDevsName: "已屏蔽的作者",
        noBlockedMemeDevs: "无屏蔽作者",
      },
      additional: {
        title: "附加",
        deleteAccount: "删除账号",
        restoreAccount: "恢复账号",
        deleteAccountAttentionTitle: "确定要删除账号？",
        deleteAccountAttentionDesc: "你可在 14 天内撤销删除。到期后，账号将被永久删除，你的个人信息也会被清除。你创建的梗图会变为匿名。",
        acceptAccountDeletion: "删除",
        accountDeletionMsg: "你的账号将在 %v 天后删除",
        accountDeletionCanceledMsg: "你已撤销账号删除",
        summoner: "邀请你的人",
      },
    },
    verification: {
      success: "账号验证成功",
      bannerMsg: "账号未验证",
      bannerBtn: "前往设置",
      settings: {
        title: "账号验证",
        accountNotVerifiedMsg: "账号未验证，请提供并确认你的邮箱",
        sendEmail: "确认邮箱",
        emailIsSent: "验证邮件已发送至你的邮箱",
        nextEmailRemaining: "再次发送前请稍等 (秒)：",
      }
    },
  },
  topMemes: {
    title: "为你精选的热门梗图",
    loginToSeeMore: "登录以查看更多梗图",
  },
  duncanChest: {
    title: "本周精选梗图",
  },
  battleField: {
    title: "选择胜者",
    left: "左边",
    right: "右边",
    fullScreen: "进入全屏",
    collapse: "收起",
    allBattlesJudged: "你已裁决所有当前可用的对决",
    signUpInfo: "注册后可在不同设备间同步，不会重复推送",
  },
  builder: {
    searchTmpl: {
      filterByForm: "按表单筛选",
      searchByTags: "按标签搜索",
      noSearchResults: "没有搜索结果",
      expandPreviewList: "全屏预览",
      collapsePreviewList: "收起预览",
    },
    meme: {
      originalLang: "原始语言",
      create: "创建梗图",
      creationDesc: "新梗图创建模式",
      editableTitlePlaceholder: "输入文本...",
      titlePlaceholder: "有趣的文字",
      headlineTitlePlaceholder: "标题（可选）",
      untranslatable: "无法翻译的梗图",
      saveAsTmpl: "保存为模板",
      upload: "创造最佳梗图！",
      successCreation: "梗图上传成功！",
      validation: {
        emptyTitle: "请填入或删除空标题",
      }
    },
    tmpl: {
      create: "创建模板",
      creationDesc: "创建新模板以供其他人使用",
      titlePlaceholder: "有趣文字",
      upload: "创建精彩模板！",
      successCreation: "模板已成功创建！",
    },
    selectImage: "选择图片",
    selectMediaFile: "图片、GIF 或视频",
    selectForm: "选择表单",
    pleaseSelectNewImage: "请选择新的图片",
    appendTitle: "添加标题",
    enterTags: "用空格分隔多个标签:",
    tagsPlaceholder: "信息标签 再来一个标签...",
  },
  support: {
    contactUsViaEmail: "联系我们，可发送邮件至：",
    feelFreeToWriteUs: "若发现问题或有改进想法，欢迎给我们写信！我们非常期待您的反馈！"
  }
} as const;