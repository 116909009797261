import {Language, Region} from "../models/Locale";
import $api from "../http";

export default class LocaleService {
    static async getLanguages(isNewLangsActive?: boolean) {
        const res = await $api.get<Language[]>(`/v1/locales/langs?new-languages=${isNewLangsActive ? "true" : "false"}`)
        return res.data
    }

    static async getRegions() {
        const res = await $api.get<Region[]>("/v1/locales/regs")
        return res.data
    }
}