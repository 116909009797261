import React, {ChangeEvent, RefObject, useContext, useEffect, useState} from "react";
import cl from "./Title.module.css";
import minus_img from "../../../../images/builder/minus.svg";
import plus_img from "../../../../images/builder/plus.svg";
import close_img from "../../../../images/close.svg";
import axes_img from "../../../../images/builder/axes.svg";
import drag_dots_img from "../../../../images/builder/drag_dots.png";
import {ConnectDragSource, useDrag} from "react-dnd";
import {argbToRgba, getTextShadowColor, shadowSize, strokeSize} from "../../../Memes/Memes";
import {observer} from "mobx-react-lite";
import {DraftContext} from "../../Builder";
import {getFontListByLang} from "../../../../../views/Fonts";
import {ConstructorType} from "../../Builder";
import {useTranslation} from "react-i18next";
import {Size} from "./TitleStore";
import {Context} from "../../../../../index";
import { isMobile } from "react-device-detect";

interface TitleToolbarProps {
    index: number,
    constructorType: ConstructorType,
}

export const TitleToolbar
    = observer(({index, constructorType} : TitleToolbarProps) => {
    const titleStore = useContext(DraftContext).draftStore.titleStore;
    const title = titleStore.titles[index];
    const [isXYCoordsPanelOpen, setIsXYCoordsPanelOpen] = useState(false);

    return (
        <div className={cl.TitleToolbarContainer}>
            <div className={cl.TitleToolbarFirstRow}>
                <Title
                    titleIndex={index}
                    constructorType={constructorType}
                />
                <FontSizeSetting //set fontSize
                    titleIndex={index}
                    fontSize={title.font_size}
                    setFontSize={titleStore.setFontSize}
                    container={titleStore.container}
                />
                <FontSetting
                    titleIndex={index}
                    value={title.font}
                    setValue={titleStore.setFont}
                />
                <ColorSetting
                    titleIndex={index}
                    value={"#"+title.text_color.toString(16).substring(2)}
                    setInput={titleStore.setColor}
                />
                <OpenXYCoordsBtn
                    handleOpenXYCoords={() => setIsXYCoordsPanelOpen(!isXYCoordsPanelOpen)}
                />
                <div className={cl.Separator}></div>
                <DropTitleBtn
                    handleDropTitle={() => titleStore.drop(index)}
                />
            </div>
            <div className={`${cl.TitleToolbarSecondRow} ${isXYCoordsPanelOpen ? cl.open : ""}`}>
                <XYSetting //set x_ratio
                    isOpen={isXYCoordsPanelOpen}
                    name={"X"}
                    titleIndex={index}
                />
                <XYSetting //set y_ratio
                    isOpen={isXYCoordsPanelOpen}
                    name={"Y"}
                    titleIndex={index}
                />
            </div>
        </div>
    );
})

interface TitleProps {
    titleIndex: number,
    constructorType: ConstructorType,
}

const Title = observer(({titleIndex, constructorType}: TitleProps) => {
    const { t } = useTranslation();
    const titleStore = useContext(DraftContext).draftStore.titleStore;
    const title = titleStore.titles[titleIndex];

    return (
        <div className={cl.Title}>
            <div>{`#${titleIndex + 1}`}</div>
            <div className={cl.TitleText}>
                {(constructorType === "Meme" && title.text.length === 0) && t("builder.meme.titlePlaceholder")}
                {(constructorType === "Meme" && title.text.length > 0) && title.text}
                {constructorType === "Template" && t("builder.tmpl.titlePlaceholder")}
            </div>
        </div>
        // <textarea
        //     className={cl.Input + " " + cl.Text}
        //     id={`titleTextInput${titleIndex}`}
        //     name={`titleText${titleIndex}`}
        //     onChange={(event) => {titleStore.setText(titleIndex, event.target.value)}}
        //     placeholder={titleStore.placeHolder}
        //     value={title.text}/>
    );
});

interface OpenXYCoordsBtnProps {
    handleOpenXYCoords(): void
}

function OpenXYCoordsBtn({handleOpenXYCoords}: OpenXYCoordsBtnProps) {
    return (
        <>
            <button className={cl.OpenXYCoordsBtn}
                    onClick={handleOpenXYCoords}>
                <img className={cl.OpenXYCoordsImg}
                     src={axes_img}
                     alt={"axes_img"}/>
            </button>
        </>
    );
}

interface ColorSettingProps {
    titleIndex: number,
    value: string,
    setInput(titleIndex: number, v: number): void,
}

function ColorSetting(props: ColorSettingProps) {
    function handleInputChange(v: string) {
        //convert #123456 RGB format to integer ARGB
        const hexARGB = "ff" + v.substring(1);
        const intARGB = parseInt(hexARGB, 16);
        props.setInput(props.titleIndex, intARGB);
    }
    return (
        <div className={cl.ColorSelectorContainer}>
            <div className={cl.ColorSelector}
                 onClick={() => handleInputChange("#000000")}
                 style={{
                     backgroundColor: "#000000",
                     borderColor: props.value === "#000000" ? "var(--main-color)" : "lightgray",
                 }}>
            </div>
            <div className={cl.ColorSelector}
                 onClick={() => handleInputChange("#ffffff")}
                 style={{
                     backgroundColor: "#ffffff",
                     borderColor: props.value === "#ffffff" ? "var(--main-color)" : "lightgray",
                 }}>
            </div>
        </div>
        // <div className={cl.ColorInputContainer}>
        //     <input className={cl.ColorInput}
        //            id={`colorInput${props.titleIndex}`}
        //         type={"color"}
        //         value={props.value}
        //         onChange={(e) => {handleInputChange(e.target.value)}}
        //     />
        // </div>
    );
}

interface FontSizeSettingProps {
    titleIndex: number,
    fontSize: number,
    setFontSize(titleIndex: number, v: number): void,
    container: Size
}

function FontSizeSetting(props: FontSizeSettingProps) {
    const scale = props.container.height*0.75; //0.75 for converting px to pt
    const viewSize = Math.round(props.fontSize*scale);
    function handleInputChange(e: ChangeEvent<HTMLInputElement>) {
        let size = parseInt(e.target.value, 10);
        if (isNaN(size)) {
            size = 0
        }
        props.setFontSize(props.titleIndex, size/scale);
    }

    return (
        <div style={{position: "relative",}}>
            <input className={cl.Input + " " + cl.FontSize}
                   id={`fontSizeInput${props.titleIndex}`}
                    onChange={(e) => handleInputChange(e)}
                    value={viewSize === 0 ? "" : viewSize}
                    type={"number"}
                    style={{
                        width: "2.5em",
                        fontSize: "1em",
                    }}>
            </input>
            <div style={{
                position: "absolute",
                right: "0",
                top: "50%",
                transform: "translate(0, -50%)",
                display: "flex",
                height: "100%",
                flexDirection: "column",
                justifyContent: "space-evenly"
            }}>
                <button className={cl.ImgBtnSetting}
                        onClick={() => props.setFontSize(props.titleIndex, (viewSize+1)/scale)}
                        style={{
                            display: "flex",
                            justifyContent: "center"
                        }}>
                    <img className={cl.ImgSetting}
                         src={plus_img}
                         alt={"plus_img"}/>
                </button>
                <button className={cl.ImgBtnSetting}
                        onClick={() => props.setFontSize(props.titleIndex, (viewSize-1)/scale)}
                        style={{
                            display: "flex",
                            justifyContent: "center"
                        }}>
                    <img className={cl.ImgSetting}
                         src={minus_img}
                         alt={"minus_img"}/>
                </button>
            </div>
        </div>
    );
}

interface DropTitleBtnProps {
    handleDropTitle(): void,
}

function DropTitleBtn({handleDropTitle}: DropTitleBtnProps) {
    return (
        <button className={cl.DropTitleBtn}
                onClick={handleDropTitle}>
            <img className={cl.DropTitleImg}
                 src={close_img}
                 alt={"close_img"}/>
        </button>
    );
}

interface FontSettingProps {
    titleIndex: number,
    value: string,
    setValue(titleIndex: number, font: string): void,
}

const FontSetting = observer((props: FontSettingProps) => {
    const { localeStore } = useContext(Context);
    const titleStore = useContext(DraftContext).draftStore.titleStore;
    const fonts = getFontListByLang(localeStore.getMemeLanguage());
    useEffect(() => {
        if (!fonts.includes(props.value as never)) {
            props.setValue(props.titleIndex, fonts[0])
        }
    }, []);

    useEffect(() => {
        const title = titleStore.getTitles()[props.titleIndex];
        title.font = getFontListByLang(localeStore.memeLanguage)[0];
        titleStore.setTitle(props.titleIndex, title)
    }, [localeStore.memeLanguage]);


    function handleFontChange(e: any) {
        props.setValue(props.titleIndex, e.target.value)
    }
    return (
        <>
            <select className={cl.FontSelector}
                id={`fontSelector${props.titleIndex}`}
                value={props.value}
                onChange={(e) => handleFontChange(e)}
                style={{fontFamily: props.value}}
            >
                {fonts.map((font) => (
                    <option
                        key={font}
                        value={font}
                        style={{fontFamily: font}}
                    >{font}
                    </option>
                ))}
            </select>
        </>
    );
})

interface XYSettingProps {
    name: "X" | "Y",
    isOpen: boolean,
    titleIndex: number,
}

const XYSetting = observer((props: XYSettingProps) => {
    const titleStore = useContext(DraftContext).draftStore.titleStore;
    const title = titleStore.titles[props.titleIndex];

    let value = 0;
    if (props.name === "X") value = Math.round(title.x_ratio*100);
    if (props.name === "Y") value = Math.round(title.y_ratio*100);
    function handlePlus() {
        if (props.name === "X") titleStore.setPosition(props.titleIndex, title.x_ratio+0.01, title.y_ratio);
        if (props.name === "Y") titleStore.setPosition(props.titleIndex, title.x_ratio, title.y_ratio+0.01);
    }
    function handleMinus() {
        if (props.name === "X") titleStore.setPosition(props.titleIndex, title.x_ratio-0.01, title.y_ratio);
        if (props.name === "Y") titleStore.setPosition(props.titleIndex, title.x_ratio, title.y_ratio-0.01);
    }
    function handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
        // console.log(e.target.value);
        const value = parseFloat(e.target.value)/100;
        // console.log(value);
        if (isNaN(value)) return;
        if (props.name === "X") titleStore.setPosition(props.titleIndex, value, title.y_ratio);
        if (props.name === "Y") titleStore.setPosition(props.titleIndex, title.x_ratio, value);
    }

    return (
        <div className={cl.XYBtnSetting}>
            <div style={{marginRight: "1vmin"}}>{props.name + "(%) :"}</div>
            <button className={cl.ImgBtnSetting}
                    style={props.isOpen ? {} : {height: 0, width: 0}}
                    onClick={handleMinus}>
                <img className={cl.ImgSetting}
                     src={minus_img}
                     alt={"minus_img"}/>
            </button>
            <input className={cl.Input + " " + cl.XYPosition}
                   id={`${props.titleIndex}_${props.name}`}
                   type="number"
                   style={props.isOpen ? {} : {height: 0,padding: 0}}
                   value={value}
                   onChange={(e) => handleInputChange(e)}
            />
            <button className={cl.ImgBtnSetting}
                    style={props.isOpen ? {} : {height: 0, width: 0}}
                    onClick={handlePlus}>
                <img className={cl.ImgSetting}
                     src={plus_img}
                     alt={"plus_img"}/>
            </button>
        </div>
    );
})

interface TitleDNDInputProps {
    index: number,
    constructorType: ConstructorType,
}

export const TitleDNDInput
    = observer(({index, constructorType}: TitleDNDInputProps) => {
    const titleStore = useContext(DraftContext).draftStore.titleStore;
    const title = titleStore.titles[index];
    const fontSize = title.font_size * titleStore.container.height;
    const textArea = titleStore.textAreas[index];
    const [isHovered, setIsHovered] = useState(false);
    const dragItemWidth = fontSize/1.3;

    // const [isFontLoaded, setIsFontLoaded] = useState(false);
    //
    // useEffect(() => {
    //     if (document.fonts) {
    //         const checkFont = async () => {
    //             try {
    //                 await document.fonts.load(`1em ${title.font}`);
    //                 if (document.fonts.check(`1em ${title.font}`)) {
    //                     setIsFontLoaded(true);
    //                 }
    //             } catch (error) {
    //                 console.error("Ошибка загрузки шрифта:", error);
    //             }
    //         };
    //         checkFont();
    //     }
    // }, [title.font]);
    // if (isFontLoaded) {
    //     console.log(title.font, " LOADED")
    //     setIsFontLoaded(false);
    // }

    useEffect(() => {
        document.fonts.ready.then(() => titleStore.setTitle(index, title));
    }, []);

    function handleTextChange(event: ChangeEvent<HTMLTextAreaElement>) {
        if (constructorType === "Meme") {
            const inputValue = event.target.value;
            const sanitizedValue = inputValue.replace(/(\n{2,})/g, '\n');
            // const trimmedValue = sanitizedValue.trimStart();
            titleStore.setText(index, sanitizedValue);
        }
    }
    function handleBlur() {
        titleStore.setText(index, title.text.trimEnd());
    }

    const [{isDragging}, dragL, dragPreviewL] = useDrag({
        type: 'textarea',
        item: {index},
        collect: (monitor: any) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const [, dragR, dragPreviewR] = useDrag({
        type: 'textarea',
        item: {index},
    });

    
    const [, dragTextareaForMobile] = useDrag({
        type: 'textarea',
        item: {index},
    });

    //set textarea placeholder color
    document.documentElement.style.setProperty("--meme-title-color", `${title.text_color}`);

    return (
        <div ref={dragPreviewL} style={{
            position: "absolute", 
            bottom: `${textArea.bottom}px`, 
            left: `${textArea.left - dragItemWidth}px`,
            zIndex: 1,
            }}>
            <div ref={dragPreviewR} style={{
                display: "flex",
                }}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}>
                {/* <div style={{cursor: "move", width: `${fontSize}px`, fontSize: `${fontSize}px`, backgroundColor: "rgba(0, 0, 0, 10%)"}}>::</div> */}
                <DragItem dragRef={dragL} pos="left" 
                        width={`${dragItemWidth}px`} 
                        heigth={`${textArea.height}px`} 
                        fontSize={`${fontSize}px`} 
                        isHovered={isHovered}/>
                <textarea className={cl.TextArea} 
                        ref={isMobile ? dragTextareaForMobile : undefined}
                        style={{
                            // cursor: "move",
                            //   position: "absolute",
                            width: `${textArea.width+2}px`,
                            height: `${textArea.height}px`,
                            minHeight: `${fontSize}px`,
                            resize: "none",
                            padding: "0",
                            textAlign: "center",
                            overflow: "hidden",

                            fontSize: `${fontSize}px`,
                            fontFamily: title.font,
                            color: "#" + argbToRgba(title.text_color.toString(16)),
                            textShadow: `${shadowSize} ${getTextShadowColor(title.text_color.toString(16))}`,
                            // textShadow: `0 0 ${strokeSize} ${getTextShadowColor(title.text_color.toString(16))}`,
                            // WebkitTextStrokeColor: `${getStrokeColor(title.text_color.toString(16))}`,
                            // WebkitTextStrokeWidth: `${strokeSize}`,

                            //   borderBottom: "0",
                            //   borderLeft: "0",
                            //borderTop: isHovered ? "25px solid rgba(200,200,200,50%)" : "0 solid rgba(200,200,200,50%)",
                            //borderRight: isHovered ? "25px solid rgba(170,170,170,50%)" : "0 solid rgba(170,170,170,50%)",
                            transition: "border 0.2s ease",
                        }}
                        id={`textTextDND${index}`}
                        onChange={handleTextChange}
                        onBlur={handleBlur}
                        value={constructorType === "Meme" ? title.text : ""}
                        placeholder={titleStore.placeHolder}
                />
                <DragItem dragRef={dragR} 
                        pos="right" 
                        width={`${dragItemWidth}px`} 
                        heigth={`${textArea.height}px`} 
                        fontSize={`${fontSize}px`}
                        isHovered={isHovered}/>
                {/* <div style={{cursor: "move", width: `${fontSize}px`, fontSize: `${fontSize}px`, backgroundColor: "rgba(0, 0, 0, 10%)"}}>::</div> */}
                {/* <div className={cl.TextAreaPopUp}
                    style={{
                        position: "absolute",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",

                        bottom: `${textArea.bottom}px`,
                        left: isHovered ? `calc(${textArea.left}px - ${fontSize*1.7}px)` : `calc(${textArea.left}px`,
                        width: isHovered ? `${fontSize*1.7}px` : 0,
                        height: textArea.height,
                        fontSize: isHovered ? fontSize : 0,

                        fontFamily: title.font,
                        color: "#" + argbToRgba(title.text_color.toString(16)),
                        transition: "width 0.2s ease, font-size 0.2s ease, left 0.2s ease",
                        zIndex: 2,
                    }}>
                    {`#${index+1}`}
                </div> */}
            </div>
        </div>
    )
})

interface DragItemProps {
    dragRef: ConnectDragSource,
    pos: "left"|"right"|"top"|"bottom",
    width: string,
    heigth: string,
    fontSize: string,
    isHovered: boolean,
}

function DragItem({dragRef, pos, width, heigth, fontSize, isHovered}: DragItemProps) {
    const dragItemBorderRadius = `calc(${fontSize}/6)`;
    return (
        <div className={cl.DragItem}
             ref={dragRef} 
             style={{
                width: width,
                height: heigth,
                // gap: dotGap, 
                borderTopLeftRadius: pos === "left" || pos === "top" ? dragItemBorderRadius : "",
                borderBottomLeftRadius: pos === "bottom" || pos === "left" ? dragItemBorderRadius : "",
                borderBottomRightRadius: pos === "bottom" || pos === "right" ? dragItemBorderRadius : "",
                borderTopRightRadius: pos === "top" || pos === "right" ? dragItemBorderRadius : "",
                opacity: isHovered ? "65%" : "10%",
                }}>
            <img src={drag_dots_img} alt={""} style={{
                height: `calc(${fontSize}/1.8)`,
                objectFit: "contain",
            }}/>
            {/* <div className={cl.DragItemColumn} style={{gap: dotGap}}>
                <div className={cl.DragItemDot} style={{width: dotSize, height: dotSize}}></div>
                <div className={cl.DragItemDot} style={{width: dotSize, height: dotSize}}></div>
                <div className={cl.DragItemDot} style={{width: dotSize, height: dotSize}}></div>
                <div className={cl.DragItemDot} style={{width: dotSize, height: dotSize}}></div>
            </div>
            <div className={cl.DragItemColumn} style={{gap: dotGap}}>
                <div className={cl.DragItemDot} style={{width: dotSize, height: dotSize}}></div>
                <div className={cl.DragItemDot} style={{width: dotSize, height: dotSize}}></div>
                <div className={cl.DragItemDot} style={{width: dotSize, height: dotSize}}></div>
                <div className={cl.DragItemDot} style={{width: dotSize, height: dotSize}}></div>
            </div> */}
        </div>
);
}