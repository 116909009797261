import React, {CSSProperties, ReactElement, RefObject, useEffect, useRef, useState} from "react";
import cl from "./ImageLoader.module.css";
import error_img from "../images/error_loading.png";
import {useObserving} from "../../hooks/useObserving";

interface ImageLoaderProps {
    url: string,
    altUrl?: string,
    imgStyle: CSSProperties,
    animStyle: CSSProperties,
    alt: string,
    ImgAddon?: ReactElement,

    forceShowAnimation?: boolean,
    isVideo?: boolean,
    autoplay?: boolean,
}

export default function ImageLoader({url, altUrl, imgStyle, animStyle, alt, ImgAddon, forceShowAnimation, isVideo, autoplay}: ImageLoaderProps) {
    const [isLoading, setIsLoading] = useState(true);
    const [imageError, setImageError] = useState(false);
    const videoRef = useRef<HTMLVideoElement>(null);

    // load and view media only if it on screen
    const observingElement = useRef<HTMLDivElement | null>(null);
    const [isReadyToLoad, setIsReadyToLoad] = useState(false);
    useObserving(observingElement, true, false, () => setIsReadyToLoad(true));

    // observer for video autoplay and auto stop
    useEffect(() => {
        if (forceShowAnimation || !isVideo) {
            return;
        }

        const videoElement = videoRef.current;
        if (!videoElement) return;
        // Callback для Intersection Observer
        const handleIntersection: IntersectionObserverCallback = (entries) => {
            if (!autoplay) {
                return
            }
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    // Если элемент видим, включаем воспроизведение
                    videoElement.play().catch((err) => {
                        console.error("Ошибка воспроизведения видео:", err);
                    });
                } else {
                    // Если элемент выходит за пределы экрана, ставим на паузу
                    videoElement.pause();
                }
            });
        };
        const observer = new IntersectionObserver(handleIntersection, {
            threshold: 0.75, // Срабатывание, если хотя бы 75% элемента видны
        });
        observer.observe(videoElement);

        return () => {
            observer.unobserve(videoElement);
        };
    }, [forceShowAnimation, isReadyToLoad]);


    function handleError() {
        setIsLoading(false);
        setImageError(true);
    }

    function getImgUrl() {
        if (imageError && altUrl) {
            return altUrl
        }
        if (imageError) {
            return error_img
        }
        return url
    }

    if (!url) {
        url = "";
    }

    // url = correctUrl(url);

    return (
        <>
            {(isLoading || forceShowAnimation) &&
                <>
                    <div className={cl.ImageLoadAnimation}
                         style={animStyle}>
                        <div ref={observingElement}
                             className={cl.ObservingElement}>
                        </div>
                    </div>
                </>
            }
            {isReadyToLoad && !isVideo && !forceShowAnimation &&
                <img key={url + altUrl}
                     onLoad={() => setIsLoading(false)}
                     onError={() => handleError()}
                     src={getImgUrl()} alt={alt}
                     style={{display: isLoading ? 'none' : 'block', ...imgStyle}}/>
            }
            {isReadyToLoad && isVideo && !forceShowAnimation &&
                <video key={url + altUrl}
                       ref={videoRef}
                       onCanPlay={() => setIsLoading(false)}
                       onError={() => handleError()}
                       controls={true}
                       muted={autoplay}
                       autoPlay={autoplay} // probably this is necessary attribute to work on apple devices
                       style={imgStyle}
                       controlsList="nodownload noplaybackrate"
                       disablePictureInPicture={true}
                       playsInline={true}>
                    <source src={url} type={"video/mp4"}/>
                </video>
            }
            {!isLoading && !imageError && ImgAddon}
        </>
    );
}

//temporarily for local dev
// export function correctUrl(url: string) {
//     if (appMode === "local" && url.includes(getConfig("dev").ServerURL) && !url.includes("/static/badges/")) {
//         return "http://localhost" + url.substring(26);
//     }
//     return url
// }