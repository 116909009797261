// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { fetchAndActivate, getRemoteConfig, getValue } from "firebase/remote-config";
import {rcStore} from "./index";
import {config} from "./config";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: process.env.REACT_APP_FB_API_KEY,
    authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FB_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FB_ID,
    measurementId: process.env.REACT_APP_FB_MEASUREMENT_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const remoteConfig = getRemoteConfig(app);
remoteConfig.settings.minimumFetchIntervalMillis = 1000;

let rcPromo1: RemoteCfg = {
    url: "",
    mode: 0,
    is_enabled: false,
    auth_type: "for-all",
};
let rcPromo2: RemoteCfg = {
    url: "",
    mode: 0,
    is_enabled: false,
    auth_type: "for-all",
};
let rcPromo3: RemoteCfg = {
    url: "",
    mode: 0,
    is_enabled: false,
    auth_type: "for-all",
};
let rcPromo4: RemoteCfg = {
    url: "",
    mode: 0,
    is_enabled: false,
    auth_type: "for-all",
};
let rcPromo5: RemoteCfg = {
    url: "",
    mode: 0,
    is_enabled: false,
    auth_type: "for-all",
};
let rcPromo6: RemoteCfg = {
    url: "",
    mode: 0,
    is_enabled: false,
    auth_type: "for-all",
};
let rcPromo7: RemoteCfg = {
    url: "",
    mode: 0,
    is_enabled: false,
    auth_type: "for-all",
};

fetchAndActivate(remoteConfig)
    .then(() => {
        const rcs = rcStore;
        const isTest = config.SiteURL !== "https://meme-battle.com";
        if (isTest) {
            console.log("Test environment for remote cfg");
        }

        let rcNewLangsActive = getValue(remoteConfig, 'dev_new_languages_active').asBoolean();
        if (isTest) {
            rcNewLangsActive = getValue(remoteConfig, 'test_web_new_languages_active').asBoolean();
        }
        rcs.setNewLangsActive(rcNewLangsActive);
        // console.log(rcNewLangsActive);

        let rcUseOAuthBtns = getValue(remoteConfig, 'dev_show_quick_auth').asBoolean();
        if (isTest) {
            rcUseOAuthBtns = getValue(remoteConfig, 'test_web_bool').asBoolean();
        }
        rcs.setUseOAuthBtns(rcUseOAuthBtns);
        // console.log(rcUseOAuthBtns);

        let rcNewYearMakeUp = getValue(remoteConfig, 'dev_new_year_make_up').asBoolean();
        if (isTest) {
            rcNewYearMakeUp = getValue(remoteConfig, 'test_web_bool').asBoolean();
        }
        rcs.setNewYearMakeUp(rcNewYearMakeUp);
        // console.log(rcNewYearMakeUp);

        let rcContentTypes = getValue(remoteConfig, 'dev_content_types').asString();
        if (isTest) {
            rcContentTypes = getValue(remoteConfig, 'test_web_content_types').asString();
        }
        rcs.setContentTypes(rcContentTypes);
        // console.log(rcContentTypes);

        let rcpjson = getValue(remoteConfig, "mkt_for_non_auth_users").asString();
        if (isTest) {
            rcpjson = getValue(remoteConfig, "test_web_for_non_auth_users").asString();
        }
        if (rcpjson !== "") {
            rcPromo1 = JSON.parse(rcpjson);
            rcPromo1.auth_type = "for-non-auth";
            rcs.appendPromoList(rcPromo1);
        }
        // console.log(rcpjson);

        rcpjson = getValue(remoteConfig, "mkt_referral_for_friends").asString();
        if (isTest) {
            rcpjson = getValue(remoteConfig, "test_web_referral_for_friends").asString();
        }
        if (rcpjson !== "") {
            rcPromo2 = JSON.parse(rcpjson);
            rcPromo2.auth_type = "for-auth";
            rcs.appendPromoList(rcPromo2);
        }
        // console.log(rcpjson);

        rcpjson = getValue(remoteConfig, "mkt_for_auth_users").asString();
        if (isTest) {
            rcpjson = getValue(remoteConfig, "test_web_for_auth_users").asString();
        }
        if (rcpjson !== "") {
            rcPromo3 = JSON.parse(rcpjson);
            rcPromo3.auth_type = "for-auth";
            rcs.appendPromoList(rcPromo3);
        }
        // console.log(rcpjson);

        rcpjson = getValue(remoteConfig, "mkt_for_everybody").asString();
        if (isTest) {
            rcpjson = getValue(remoteConfig, "test_web_for_everybody").asString();
        }
        if (rcpjson !== "") {
            rcPromo4 = JSON.parse(rcpjson);
            rcPromo4.auth_type = "for-all";
            rcs.appendPromoList(rcPromo4);
        }
        // console.log(rcpjson);
    })
    .catch((err) => {
        console.log(err);
    }).finally(() => {
        rcStore.setIsInited(true);
    });


export interface RemoteCfg {
    url: string,
    mode: number,
    is_enabled: boolean,
    auth_type: "for-all" | "for-auth" | "for-non-auth",
}