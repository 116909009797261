export const translationsJA = {
    common: {
        and: "と",
        or: "または",
    },
    ui: {
        defErrMsg: "何か問題が起こりました :(",
        notFoundErrMsg: "404. ページが見つかりません",
        loading: "読み込み中...",
        downloadMsg: "まもなくダウンロード開始",
        linkCopied: "リンクをクリップボードにコピーしました",
        pending: "保留中...",
        goToWebsite: "サイトへ移動",
        localMeme: "ローカルミーム",
        delete: "削除",
        submit: "確認",
        save: "保存",
        cancel: "キャンセル",
        tag: {
            showTags: "タグを表示",
            hideTags: "タグを隠す",
            clickToBlock: "ブラックリストにタグを追加するにはクリック",
            clickToUnblock: "タグのブロックを解除するにはクリック",
            tagBlocked: "タグがブロックされました。このタグのミームは除外されます",
            tagUnblocked: "タグのブロックが解除されました"
        },
        memeDev: {
            block: "ブロック",
            unblock: "解除",
            memeDevBlocked: "ユーザーをブロックしました。投稿は除外されます",
            memeDevUnblocked: "ユーザーのブロックを解除しました",
        },
        betaInfo: "ベータテストにご参加いただきありがとうございます。\nもしバグや改善アイデアがあれば、メールでご連絡ください：",
        mobileMarketNoticeTitle: "アプリは各ストアで入手できます:",
        cookieAttention: {
            title: "Cookieを使用しています",
            info: "当サイトを利用することで、Cookieの使用に同意いただいたものとみなします。認証にCookieを使っていますので、使いやすさを保証するのに役立ちます。",
            understood: "了解しました",
        },
        featureTemporarilyDisabled: "この機能は一時的に無効です。現在対応中です",
        memeRatingTitleGlobal: "推定グローバル評価値。勝敗数と総投票数に依存します",
        memeRatingTitleLocal: "推定ローカル評価値。勝敗数と総投票数に依存します",
        memeWins: "勝利数",
        memeLosts: "敗北数",
    },
    specials: {
        greetings: "2025年 あけましておめでとう！",
        countdown: "新年まで残り",
        days: "日",
        hours: "時間",
        minutes: "分",
        seconds: "秒",
        letsWait: "待機中 🎄"
    },
    apiError: {
        _400: {
            invalidTemplateForm: "テンプレートフォームが無効です",
            invalidBirthDate: "生年月日が無効です",
            imageMissing: "画像が見つかりません",
            emailIsNotValid: "メールアドレスが無効、または既存のユーザーがいます",
        },
        _401: {
            wrongLoginPwd: "ログインまたはパスワードが違います",
            wrongPwd: "パスワードが違います",
        },
        _403: {
            accountNotVerified: "アカウントが未認証です",
            accessDenied: "アクセスが拒否されました",
        },
        _404: {
            notFound: "要求されたリソースは見つかりません",
            memeNotFound: "ミームが見つかりません",
            userNotFound: "ユーザーが見つかりません",
            templateNotFound: "テンプレートが見つかりません",
            langNotSupported: "この言語はサポートされていません",
            regNotSupported: "地域が存在しないかサポート外です",
            activationLink: "アクティベーションリンクが無効または期限切れです",
            pwdRestoreLink: "パスワード復旧リンクが無効または期限切れです",
            unknownReportCategory: "不明な通報カテゴリです",
        },
        _409: {
            cantJudge: "このミームの組み合わせは評価対象外です",
            emailExists: "このメールのユーザーが既に存在します",
            nicknameExists: "このニックネームのユーザーが既に存在します",
            alreadyReported: "この投稿は既に通報済みです",
        },
        _413: {
            imgTooLarge: "画像ファイルは10MB以下である必要があります",
            stringBodyTooLarge: "テキストサイズは10KB以下である必要があります",
        },
        _422: {
            imgFileType: "ファイル形式または拡張子がサポート外です",
            imgRatio: "画像の縦横比が大きすぎます（最大10:1）",
            lang: "選択した言語はサポートされていません",
            reg: "選択した地域はサポートされていません",
            userCreation: {
                birthDate: "年齢は12歳以上100歳以下である必要があります",
                email: "有効なメール形式が必要です",
                firstName: "名は100文字を超えてはいけません",
                lastName: "姓は100文字を超えてはいけません",
                nickname: "ニックネームは3～20文字で、英数字のみ使用可能で、数字のみは不可",
                password: "パスワードは6～20文字である必要があります",
            },
            memeOrTemplateCreation: {
                titles: {
                    memeTitles: "タイトルがない場合は翻訳不能なミームのみ作成できます",
                    tmplTitles: "テキストなしのテンプレートは作成できません",
                    font_size: "フォントサイズが大きすぎるか小さすぎます",
                    text: "タイトルは空であってはならず、2000文字以内にしてください",
                    angle: "タイトル角度が無効です",
                    textColor: "タイトルカラーが無効です",
                    xRatio: "縦方向にタイトルが画像をはみ出しています",
                    yRatio: "横方向にタイトルが画像をはみ出しています",
                },
                templateAddon: {
                    form: "ミームまたはテンプレートフォームが無効です",
                    tags: "タグは空や重複不可。合計100文字以内にしてください",
                },
            }
        },
        _429: {
            creationLimit: "投稿制限を超えました。1日にミーム10件とテンプレート10件まで可能です",
            emailSendingLimit: "メール送信回数を超えました。1分に1通、1週間に10通までです",
        },
    },
    navbar: {
        hotMemes: "ホット",
        topMemeDevs: "トップ作者",
        battleField: "バトル",
        builder: "ビルダー",

        localeSettingsTitle: "ミーム言語",
        additional: "追加",
        support: "サポートに連絡",
    },
    report: {
        popUpTitle: "通報",
        modalTitle: "通報の理由を選択",
        spam: "スパム",
        violenceOrHostility: "暴力または敵意",
        adultContent: "アダルト内容",
        other: "その他",
        translation: "誤訳",
        position: "位置が不適切",
        successSending: "通報を送信しました",
    },
    authForm: {
        login: {
            title: "ログイン",
            email: "メール",
            password: "パスワード",
            forgotPwd: "パスワードを忘れましたか？",
            restorePwd: "パスワード復旧",
        },
        signUp: {
            title: "新規登録",
            nickname: "ニックネーム（英数字）",
            firstname: "名",
            lastname: "姓",
            birthDate: "生年月日",
            birthDateTooltip: "より適切なミームをおすすめするために利用します",
            enterYourAge: "あなたの年齢を入力",
            yourAge: "あなたの年齢",
            memesLang: "ミームの言語",
            region: "地域",
            email: "メール",
            password: "パスワード",
            submitPassword: "パスワードを確認",
            summoner: "招待者ID・ニックネーム",
            summonerTooltip: "友達から招待された場合、そのIDやニックネームを入力してください",
            agreement: {
                agree: "私は",
                privacy: "プライバシーポリシー",
                and: "と",
                terms: "利用規約",
                ending: "に同意します"
            },
            agreementModal: {
                title: "ユーザー規約",
                byStaying: "サイトを利用することで、あなたは",
                privacy: "プライバシーポリシー",
                and: "と",
                terms: "利用規約",
                ending: "に同意します",
                acceptBtn: "同意する"
            },
            successSignUp: "アカウント確認リンクを記載したメールを送信しました",
            error: {
                pwdDontMatch: "パスワードが一致しません",
                age: "登録には12歳以上100歳以下である必要があります",
            }
        },
    },
    profile: {
        premium: {
            premiumAccount: "プレミアムアカウント",
            daysLeft: "プレミアム終了までの日数: ",
            monthsLeft: "プレミアム終了までの月数: "
        },
        popUp: {
            myProfile: "マイプロフィール",
            settings: "設定",
            logout: "ログアウト",
        },
        public: {
            wins: "勝利",
            defeats: "敗北",
            judged: "評価済",
            position: "順位",
            memesCount: "ミーム数",
            rating: "レーティング",
        },
        level: {
            l1: "ジュニア・ミーム開発者",
            l2: "ミドル・ミーム開発者",
            l3: "シニア・ミーム開発者",
            l4: "ミーム・グル",
            l5: "ほぼミーム神",
            l6: "ミーム神",
            maxGradeReached: "最高ランクに到達しました！",
            nextGrade: "次のランク:",
            completedConditions: "達成した条件:",
            unfulfilledConditions: "未達の条件:",
        },
        memes: {
            title: "作者のミーム",
            sort: {
                firstFresh: "新しい順",
                firstOld: "古い順",
                ratingDown: "評価が高い順",
                ratingUp: "評価が低い順",
            },
            areYouSureDeleteMeme: "このミームを本当に削除しますか？",
            successDeletion: "ミームを削除しました",
        },
        badges: {
            percentOfPlayersEarned: "%v% のユーザーがこの実績を獲得",
            nextLevel: "次のレベル",
        },
        settings: {
            title: "プロフィール設定",
            nicknameDesc: "ニックネームを変更",
            selectAvatar: "アバターを選択",
            avatarUpdated: "アバターは正常に更新されました",
        },
        advancedSettings: {
            title: "設定",
            locale: {
                title: "ロケール",
                memesLangName: "言語",
                memesLangDesc: "ミーム言語を変更",
                regName: "地域",
                regDesc: "地域を変更",
            },
            security: {
                title: "セキュリティ",
                emailName: "メールアドレス",
                emailDesc: "あなたのメール",
                pwdName: "パスワード",
                changePwd: "パスワード変更",
                fullLogoutName: "ログアウト",
                fullLogoutDesc: "すべての端末からログアウト",
                enterCurrentPwd: "現在のパスワードを入力",
                enterNewPwd: "新しいパスワードを入力",
                pwdUpdateOrRestoreSuccess: "パスワードが変更されました",
                enterEmailToRestorePwd: "登録メールアドレスを入力してください",
                sendRestoreLink: "復旧リンクを送信",
                emailToRestorePwdSent: "復旧リンクをメールで送信しました",
            },
            blackList: {
                title: "ブラックリスト",
                clearBlackList: "リストをクリア",
                clearBlockedTagsSubmitDesc: "ブロック中のタグをすべて消去しますか？",
                clearBlockedMemeDevsSubmitDesc: "ブロック中のユーザーをすべて消去しますか？",
                blockedTagsName: "ブロック済みタグ",
                noBlockedTags: "ブロックされたタグはありません",
                blockedMemeDevsName: "ブロック済みユーザー",
                noBlockedMemeDevs: "ブロックされたユーザーはいません",
            },
            additional: {
                title: "その他",
                deleteAccount: "アカウント削除",
                restoreAccount: "アカウント復旧",
                deleteAccountAttentionTitle: "本当にアカウントを削除しますか？",
                deleteAccountAttentionDesc: "14日以内は削除を取り消せます。それ以降は完全に削除され、個人データは消去されます。作成したミームは匿名になります。",
                acceptAccountDeletion: "削除",
                accountDeletionMsg: "アカウントは %v 日後に削除されます",
                accountDeletionCanceledMsg: "アカウント削除をキャンセルしました",
                summoner: "あなたを招待したユーザー",
            },
        },
        verification: {
            success: "あなたのアカウントは認証されました",
            bannerMsg: "アカウントが未確認です",
            bannerBtn: "設定へ移動",
            settings: {
                title: "アカウント認証",
                accountNotVerifiedMsg: "アカウントは未認証です。メールアドレスを入力して確認してください",
                sendEmail: "メール確認",
                emailIsSent: "認証リンクを含むメールを送信しました",
                nextEmailRemaining: "再送信するにはお待ちください。\n残り（秒）:",
            }
        },
    },
    topMemes: {
        title: "あなた向けのホットミーム",
        loginToSeeMore: "ログインするとさらに多くのミームが見られます",
    },
    duncanChest: {
        title: "今週のおすすめミーム",
    },
    battleField: {
        title: "勝者を選択",
        left: "左",
        right: "右",
        fullScreen: "全画面表示にする",
        collapse: "折りたたむ",
        allBattlesJudged: "利用可能な全バトルを評価済みです",
        signUpInfo: "登録すると、別のデバイスでもミームが重複しません",
    },
    builder: {
        searchTmpl: {
            filterByForm: "フォームで絞り込む",
            searchByTags: "タグ検索",
            noSearchResults: "該当する結果はありませんでした",
            expandPreviewList: "全画面表示",
            collapsePreviewList: "折りたたむ",
        },
        meme: {
            originalLang: "オリジナル言語",
            create: "ミーム作成",
            creationDesc: "新しいミームを作るモード",
            editableTitlePlaceholder: "テキストを入力…",
            titlePlaceholder: "面白いテキスト",
            headlineTitlePlaceholder: "タイトル（任意）",
            untranslatable: "翻訳不要なミーム",
            saveAsTmpl: "テンプレートとして保存",
            upload: "最高のミームを作ろう！",
            successCreation: "ミームをアップロードしました！",
            validation: {
                emptyTitle: "空のタイトルを埋めるか削除してください",
            }
        },
        tmpl: {
            create: "新規テンプレート作成",
            creationDesc: "ほかの作者が使えるテンプレートを新規作成",
            titlePlaceholder: "面白いテキスト",
            upload: "素晴らしいテンプレートを作成！",
            successCreation: "テンプレートを作成しました！",
        },
        selectImage: "画像を選択",
        selectMediaFile: "画像、gif か動画",
        selectForm: "フォームを選択",
        pleaseSelectNewImage: "新しい画像を選択してください",
        appendTitle: "タイトルを追加",
        enterTags: "スペース区切りでタグを入力:",
        tagsPlaceholder: "情報タグ 別タグ...",
    },
    support: {
        contactUsViaEmail: "以下のアドレス宛にメールでお問い合わせください: ",
        feelFreeToWriteUs: "バグや改善案があれば、気軽にご連絡を。フィードバックをお待ちしています！"
    }
} as const;
